<template>
    <div id="base_area" @click.stop="hideMenu">
        <headerBar :title="headerTitle" @menuOpen="menuOpen"></headerBar>
        <router-view :key="paging"/>
        <sideMenu :onmenuFlg="onmenuFlg" :managerFlg="managerFlg" :payslipFlag="payslipFlag" :runingMode="runingMode" @menu_click="menu_click"></sideMenu>
        <div v-if="backPanelFlg" class="back-panel"></div>
    </div>
</template>

<script>
import Vue from 'vue';
import headerBar  from '@/components/header.vue';
import sideMenu  from '@/components/sidemenu.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "index",
    mixins: [utilityMixin],
    components: {
        'headerBar': headerBar,
        'sideMenu': sideMenu
    },
    data() {
        return {
            onmenuFlg: false,
            managerFlg: 0,
            payslipFlag: 0,
            paging: 0,
            backPanelFlg: false,
            runingMode: process.env.VUE_APP_RUNNING_MODE,
            serverParam: null
        }
    },
    computed: {
        headerTitle() {
            return this.$store.state.operator.headerTitle;
        },
        backPanel() {
            return this.$store.state.operator.backPanel;
        },
        darkMode() {
            return this.$store.state.config.darkMode;
        }
    },
    watch: {
        headerTitle(val, old) {
            //--
        },
        backPanel(nv, ov) {
            if (nv == "on") {
                this.backPanelFlg = true;
            } else {
                this.backPanelFlg = false;
            }
        },
        darkMode(nv, ov) {
            if (nv == "dark") {
                document.documentElement.setAttribute("data-theme", "dark");
            } else if (nv == "light") {
                document.documentElement.setAttribute("data-theme", "light");
            } else {
                if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                    document.documentElement.setAttribute("data-theme", "dark");
                } else {
                    document.documentElement.setAttribute("data-theme", "light");
                }
            }
        }
    },
    created() {
        if ((this.$store.state.config.darkMode === "") || (this.$store.state.config.darkMode === undefined) || (this.$store.state.config.darkMode === null)) {
            let darkMode = "";

            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                darkMode = "dark";
            }

            if (darkMode === "") {
                if (localStorage.getItem('config')) {
                    try {
                        const configDb = JSON.parse(localStorage.getItem('config'));
                        darkMode = configDb.darkMode;
                    } catch(e) {
                        localStorage.removeItem('config');
                    }
                }
            }

            this.$store.commit("config_darkMode", darkMode);
        } else {
            if (this.$store.state.config.darkMode == "dark") {
                document.documentElement.setAttribute("data-theme", "dark");
            } else if (this.$store.state.config.darkMode == "light") {
                document.documentElement.setAttribute("data-theme", "light");
            } else {
                if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                    document.documentElement.setAttribute("data-theme", "dark");
                } else {
                    document.documentElement.setAttribute("data-theme", "light");
                }
            }
        }
    },
    mounted() {
        this.showNotifications();
        
        this.$nextTick(function() {
            let auth = this.$store.state.operator.authority;
            if ((auth == "99") || (auth == "10")) {
                this.managerFlg = auth - 0;
            }
            let payslipFlag = this.$store.state.operator.payslipFlag;
            if (payslipFlag == "1") {
                this.payslipFlag = 1;
            }
            this.main();
        });
    },
    methods: {
        async main() {
            //-- メンテナンス中チェック
            let r = await this.getSystemParam();
            if (this.serverParam == undefined) {
                this.$router.push("/");
                return;
            } else if (this.serverParam.NoAccess == 1) {
                this.$router.push("/");
                return;
            }

            let deviceCode = this.$cookies.get("DeviceCode") || '';
            let account = this.$cookies.get("Account") || '';

            if ((deviceCode == '') || (deviceCode == undefined)) {
                this.$router.push("/");
            }
            if ((account == '') || (account == undefined)) {
                this.$router.push("/");
            }
            
            //-- ログインチェック
            r = await this.chkAccount(account, deviceCode);
        },
        async getSystemParam() {
            try {
                let ress = await Vue.$cache.get('system/param/', undefined, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        if ( ress.data.param != "" ) {
                            //--
                            this.serverParam = ress.data.param;
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        async chkAccount(account, deviceCode) {
            try {
                let param = {
					account: account,
					deviceCode: deviceCode
                };
                let ress = await Vue.$cache.post('account/authkey/', param, this.$store);
                if (ress.status == undefined) {
                    throw "ress:"+ress;
                }
                if (ress.status == 200) {
                    if (ress.data == undefined) {
                        throw "status:"+ress.status+" /data:"+ress.data;
                    }
                    if (ress.data.status == "ok") {
                        if (ress.data.data.checked == "1") {
                        } else {
                            this.$router.push("/");
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
                this.$router.push("/");
            }
        },
        menuOpen() {
            this.onmenuFlg = !this.onmenuFlg;
            this.$store.dispatch("operator_baseClick", "on");
        },
        hideMenu() {
            if (this.onmenuFlg) {
                this.onmenuFlg = false;
            }
            this.$store.dispatch("operator_baseClick", "on");
        },
        menu_click(name) {
            switch (name) {
                case 'stamping':
                    this.stamping();
                    break;
                case 'applyLeave':
                    this.applyLeave();
                    break;
                case 'substituteHoliday':
                    this.substituteHoliday();
                    break;
                case 'record':
                    this.record();
                    break;
                case 'applyPayment':
                    this.applyPayment();
                    break;
                case 'payslip':
                    this.payslip();
                    break;
                case 'control':
                    this.control();
                    break;
                case 'workSituation':
                    this.workSituation();
                    break;
                case 'staff':
                    this.staff();
                    break;
                case 'sheet':
                    this.sheet();
                    break;
                case 'leaveApproval':
                    this.leaveApproval();
                    break;
                case 'accountInfo':
                    this.accountInfo();
                    break;
                case 'config':
                    this.config();
                    break;
                case 'logoff':
                    this.logoff();
                    break;
            }
        },
        jump_pg(key, param) {
            this.hideMenu();
            this.paging += 1;
            if (param != undefined) {
                this.$store.commit("operator_routeParam", param);
            }
            this.$router.push(key, () => {});
        },
        stamping() {
            let uid = this.$store.state.operator.accountId;
            let accountId = this.$store.state.operator.activeAccountId;

            if (uid != accountId) {
                this.$store.commit("operator_activeAccountId", uid);
                this.$store.commit("operator_activeYear", 0);
                this.$store.commit("operator_activeMonth", 0);
                this.$store.commit("operator_activeSheet", "");
            }

            this.jump_pg('/kintai/');
        },
        applyLeave() {
            let uid = this.$store.state.operator.accountId;
            let accountId = this.$store.state.operator.activeAccountId;

            if (uid != accountId) {
                this.$store.commit("operator_activeAccountId", uid);
                this.$store.commit("operator_activeYear", 0);
                this.$store.commit("operator_activeMonth", 0);
                this.$store.commit("operator_activeSheet", "");
            }

            this.jump_pg('/kintai/applyleave', {edited: '0'});
        },
        substituteHoliday() {
            let uid = this.$store.state.operator.accountId;
            let accountId = this.$store.state.operator.activeAccountId;

            if (uid != accountId) {
                this.$store.commit("operator_activeAccountId", uid);
                this.$store.commit("operator_activeYear", 0);
                this.$store.commit("operator_activeMonth", 0);
                this.$store.commit("operator_activeSheet", "");
            }

            this.jump_pg('/kintai/substituteHoliday');
        },
        record() {
            let uid = this.$store.state.operator.accountId;
            let accountId = this.$store.state.operator.activeAccountId;

            if (uid != accountId) {
                this.$store.commit("operator_activeAccountId", uid);
                this.$store.commit("operator_activeYear", 0);
                this.$store.commit("operator_activeMonth", 0);
                this.$store.commit("operator_activeSheet", "");
            }
            this.$store.commit("operator_manager", "");

            this.jump_pg('/kintai/record');
        },
        applyPayment() {
            let uid = this.$store.state.operator.accountId;
            let accountId = this.$store.state.operator.activeAccountId;

            if (uid != accountId) {
                this.$store.commit("operator_activeAccountId", uid);
                this.$store.commit("operator_activeYear", 0);
                this.$store.commit("operator_activeMonth", 0);
                this.$store.commit("operator_activeSheet", "");
            }

            this.jump_pg('/kintai/applypayment/');
        },
        payslip() {
            let uid = this.$store.state.operator.accountId;
            let accountId = this.$store.state.operator.activeAccountId;

            if (uid != accountId) {
                return;
            }

            this.jump_pg('/kintai/payslip/');
        },
        accountInfo() {
            let uid = this.$store.state.operator.accountId;
            let accountId = this.$store.state.operator.activeAccountId;

            if (uid != accountId) {
                this.$store.commit("operator_activeAccountId", uid);
            }
            this.jump_pg('/account/', {edited: '0'});
        },
        config() {
            let uid = this.$store.state.operator.accountId;
            let accountId = this.$store.state.operator.activeAccountId;

            if (uid != accountId) {
                this.$store.commit("operator_activeAccountId", uid);
            }
            this.jump_pg('/kintai/config/');
        },
        logoff() {
            this.logout_init();
            this.jump_pg('/');
        },
        control() {
            this.jump_pg('/kintai/managed');
        },
        workSituation() {
            this.jump_pg('/kintai/workSituation');
        },
        staff() {
            this.jump_pg('/account/managed');
        },
        sheet() {
            this.jump_pg('/kintai/sheetmng');
        },
        leaveApproval() {
            this.jump_pg('/kintai/leaveApproval');
        },
        async showNotifications() {
            try {
                let param = {
                    accountId: this.$store.state.operator.accountId
                };
                let ress = await Vue.$cache.post('account/notifications/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        if (ress.data.data.yukyuSyokaFlag) {
                            alert("有休消化日数を確認して下さい。");

                        }
                        if (ress.data.data.shimeTimeFlag) {
                            let m = ress.data.data.shimeMonth;
                            alert(m+"月分の出勤簿を確定して下さい。");
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch(error) {
                console.log(error);
            }
        },
        empty() {

        }
    }
}
</script>

<style lang="less">
#base_area {
    width: 100%;
    
    .back-panel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.3);
        z-index: 99990;
    }
}
</style>
<template>
    <div id="holidaysPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <div class="year_area">
                    <span class="btnMoveYearPre" @click.stop="mvYear(-1)">＜</span><span class="year">{{activeYear}}年</span><span class="btnMoveYearNext" @click.stop="mvYear(1)">＞</span>
                    <button class="button cancel" @click.stop="cancel">閉じる</button>
                </div>
            </div>
            <!-- インフォ -->
            <div class="info_area">
                <holiCalen v-for="m of 12" :key="m" class="calen_area" :year="activeYear" :month="m" :days="holidays" @cellClick="cell_click"></holiCalen>
            </div>
            <!-- フッター -->
            <div class="footer">
                <button class="button cancel" @click.stop="cancel">閉じる</button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import calendarCell from '@/components/holidaysCalendar.vue'
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: 'holidaysPg',
    mixins: [utilityMixin],
    components: {
        'holiCalen': calendarCell
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            accountId: "",
            code: "",
            activeYear: 0,
            holidays: [],
            param: {}
        };
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", "休業日指定");
            this.$store.dispatch("operator_baseClick", "");

            this.code = this.$route.params.code;

            this.accountId = this.$store.state.operator.accountId;
            this.param = this.$store.state.operator.routeParam;

            const now = new Date();

            if (this.activeYear == 0) {
                this.activeYear = now.getFullYear();
            }

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                let param = {
                    code: this.code
                };
                let ress = await Vue.$cache.post('record/holidays/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.holidays = ress.data.data;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        async saveData(date) {
            try {
                let param = {
                    code: this.code,
                    date: date,
                    updateId: this.accountId,
                };
                let ress = await Vue.$cache.post('record/holidays/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.getData();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        mvYear(y) {
            this.activeYear = this.activeYear + y;
            this.getData();
        },
        cancel() {
            if (this.param.edited == '1') {
                this.$router.go(-1);
            } else {
                this.editing = false;
                this.scrollTop(0);
            }
        },
        cell_click(date) {
            this.saveData(date);
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#holidaysPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .header {
            height: 42px;

            .year_area {
                width: 100%;
                font-size: 1.3rem;
                font-weight: bold;
                display: inline;

                .btnMoveYearPre {
                    margin-right: 8px;
                    cursor: pointer;
                }

                .btnMoveYearNext {
                    margin-left: 8px;
                    cursor: pointer;
                }
            }

            .cancel {
                float: right;
            }
        }

        .info_area {

            .calen_area {
                float: left;
            }
        }

        .footer {
            clear: both;
            text-align: center;
            padding-top: 22px;

            .cancel {
                width: 180px;
            }
        }
    }

    .button {
        padding: 3px 12px;
        font-weight: bold;
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:500px) {
    #holidaysPg {
        .main_area {
            padding: 78px 12px 28px;
        }
    }
}
</style>
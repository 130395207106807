<template>
    <div id="applyGoodsPayment">
        <div v-if="list.length == 0" class="empty_area">表示できる物品購入申請はありません</div>
        <div v-if="list.length > 0" class="list_area" ref="list">
            <info class="info" v-for="(dat, ix) in list" :key="ix"
                :id="dat.id"
                :date="dat.date"
                :payDate="dat.payDate"
                :receiptName="dat.receiptName"
                :itemName="dat.itemName"
                :money="dat.total"
                @menu_popup="menu_popup($event, ix)"
            ></info>
            <ul v-show="menuFlag" ref="menu" class="menu_area">
                <li><div @click.stop="onEdit()">編集</div></li>
                <li><div @click.stop="onDelete()">削除</div></li>
                <li><div @click.stop="onCopy()">コピーして新規作成</div></li>
                <li><div @click.stop="onDownloadPDF()">PDFでダウンロード</div></li>
            </ul>
        </div>
        <div class="backBtn" @click.stop="backPage()"><icon name="chevron-left" class="icon"></icon> 戻る</div>
        <div class="newCreateBtn" @click.stop="createApply">+</div>
    </div>
</template>

<script>
import Vue from 'vue';
import info from '@/components/goodsPaymentInfo.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "applyGoodsPaymentPg",
    mixins: [utilityMixin],
    components: {
        'info': info
    },
    data() {
        return {
            headerTitle: "物品購入精算",
            accountId: "",
            activeAccountId: "",
            userName: "",
            list: [],
            page: 0,
            maxItems: 10,
            acquiring: false,
            end: false,
            menuFlag: false,
            selectId: null,
            selectIdx: null
        };
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;

            this.getList();
        });
    },
    methods: {
        async getList() {
            try {
                this.acquiring = true;
                let param = {
                    accountId: this.activeAccountId,
                    page: this.page,
                    maxItems: this.maxItems
                };
                let ress = await Vue.$cache.post('payment/goods/reki/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.list = this.list.concat(ress.data.data.list);
                        this.userName = ress.data.data.userName;
                        if (ress.data.data.list.length < this.maxItems) {
                            this.end = true;
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            } finally {
                this.acquiring = false;
            }
        },
        menu_popup(e, idx) {
            let top = e.e.clientY + this.$el.scrollTop - 98;
            let h = 122;
            if ((top + h) > this.$refs.list.offsetHeight) {
                let m = this.$refs.list.offsetHeight - h;
                top = m;
            }
            this.$refs.menu.style.top = top+"px";

            this.selectId = e.id;
            this.selectIdx = idx;
            this.menuFlag = true;
        },
        onEdit() {
            this.menuFlag = false;
            this.editPage(this.selectId, this.selectIdx);
        },
        editPage(id, idx) {
            this.jump_pg("/kintai/applypayment/goods/edit/"+id);
        },
        onDelete() {
            this.menuFlag = false;
            this.delete(this.selectId, this.selectIdx);
        },
        delete(id, idx) {
            if (confirm("指定のデータを削除しますか？") == false) {
                return;
            }
            this.deleteData(id, idx);
        },
        onCopy() {
            this.menuFlag = false;
            this.copy(this.selectId, this.selectIdx);
        },
        copy(id, idx) {
            this.jump_pg("/kintai/applypayment/goods/new/"+id);
        },
        createApply() {
            this.jump_pg("/kintai/applypayment/goods/new/0");
        },
        onDownloadPDF() {
            this.menuFlag = false;
            this.downloadPDF(this.selectId, this.selectIdx);
        },
        async deleteData(id, idx) {
            try {
                let param = {
                    accountId: this.activeAccountId,
                    id: id
                };
                let ress = await Vue.$cache.post('payment/goods/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        const dem = this.list.splice(idx, 1);
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        async downloadPDF(id, idx) {
            try {
                let name = this.userName.replace(/\s+/g, "");
                let pdfFileName = this.list[idx].date.replace(/-/g,"")+"_"+this.list[idx].total+"_物品購入精算_"+name+".pdf"
                let param = {
                    accountId: this.activeAccountId,
                    id: id,
                    applyMode: "goods"
                };

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'blob',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };

                let ress = await Vue.$cache.post('payment/goods/pdf/', param, this.$store, request);
                if (ress.status == 200) {
                    const url = URL.createObjectURL(new Blob([ress.data]));

                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', pdfFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        list_scroll(e) {
            let bottomOfWindow = this.$refs.list.offsetHeight - this.$el.offsetHeight - 30 <= this.$el.scrollTop;
            if (bottomOfWindow && !this.end && !this.acquiring) {
                ++this.page;
                this.getList();
            }
        },
        backPage() {
            this.jump_pg("/kintai/applypayment");
        },
        jump_pg(key, param) {
            this.paging += 1;
            if (param != undefined) {
                this.$store.commit("operator_routeParam", param);
            }
            this.$router.push(key, () => {});
        },
        base_click() {
            this.menuFlag = false;
        }
    }
}
</script>

<style lang="less" scoped>
#applyGoodsPayment {
    width: 100%;
    height: 100%;
    overflow: auto;

    .empty_area {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-around;
        align-items: center;
        font-weight: bold;
    }

    .list_area {
        position: relative;
        min-height: 100%;
        
        .info {
            margin-bottom: 10px;
        }

        .menu_area {
            position: absolute;
            right: 24px;
            background-color: var(--backColor);
            color: var(--foreColor);
            min-width: 100px;
            margin: 0;
            padding: 0;
            list-style: none;
            box-shadow: 0 0 8px 2px rgba(0,0,0,0.2);
            border: 1px solid var(--grayoutBackColor);
            border-radius: 4px;

            li {
                display: inline;
                padding: 0;
                margin: 0;

                div {
                    padding: 6px 12px 0;
                }

                &:last-child {
                    div {
                        padding: 6px 12px;
                    }
                }
            }
        }
    }

    .backBtn {
        position: absolute;
        top: 58px;
        left: 12px;
        cursor: pointer;
        color: #42a4ff;

        .icon {
            vertical-align: text-top;
        }
    }

    .icon {
        fill: #42a4ff;
    }

    .newCreateBtn {
        position: absolute;
        top: 56px;
        right: 12px;
        width: 28px;
        height: 28px;
        border: 1px solid #5c5c5c;
        border-radius: 50%;
        background-color: var(--foreColor);
        color: var(--backColor);
        font-weight: bold;
        font-size: 1.1rem;
        text-align: center;
        box-shadow: 1px 0px 3px 3px rgba(0,0,0,0.2);
        cursor: pointer;
        z-index: 9989;
    }
}
</style>
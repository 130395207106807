<template>
    <div id="sheetPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">

            </div>
            <!-- インフォ -->
            <div class="info_area">
                <div class="fase"></div>
                <div class="detail_area">
                    <div class="titlecell">コード</div>
                    <div class="datacell"><span v-if="!newedit">{{sendDt.code}}</span><input v-if="newedit" type="text" class="code" v-model="sendDt.code"/></div>
                    <div class="titlecell">シート名</div>
                    <div class="datacell"><input type="text" class="name" v-model="sendDt.name"/></div>
                    <div class="titlecell">開始日</div>
                    <div class="datacell"><input type="text" class="startDay" v-model="sendDt.startDay"/></div>
                    <div class="titlecell">土曜日</div>
                    <div class="datacell"><label><input type="checkbox" v-model="sendDt.workOnSaturday"/>&nbsp;休み</label></div>
                    <div class="titlecell">日曜日</div>
                    <div class="datacell"><label><input type="checkbox" v-model="sendDt.workOnSunday"/>&nbsp;休み</label></div>
                    <div class="titlecell">祝日</div>
                    <div class="datacell"><label><input type="checkbox" v-model="sendDt.workOnHoliday"/>&nbsp;休み</label></div>
                    <div class="titlecell">指定休日</div>
                    <div class="datacell">
                        <textarea v-model="sendDt.originHolidays"></textarea>
                    </div>
                    <div class="titlecell">有効／無効</div>
                    <div class="datacell"><label><input type="checkbox" v-model="sendDt.useFlag"/>&nbsp;有効にする</label></div>
                </div>
                <div class="stamp"></div>
            </div>
            <!-- フッター -->
            <div class="footer">
                <button v-if="!editing" class="button hensyu" @click.stop="editSrat">編集</button>
                <button v-if="editing" class="button save" @click.stop="saveDataPre">登録</button>
                <button v-if="editing" class="button cancel" @click.stop="cancel">キャンセル</button>
                <button v-if="deleted" class="button delete" @click.stop="deleteDataPre">削除</button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: 'sheetPg',
    mixins: [utilityMixin],
    computed: {
        newedit() {
            return ((this.code == "")||(this.code == "@")) ? true : false;
        },
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            accountId: "",
            code: "",
            editing: false,
            deleted: false,
            sendDt: {
                code:"",
                name: "",
                startDay: "",
                workOnSaturday: "",
                workOnSunday: "",
                workOnHoliday: "",
                originHolidays: "",
                useFlag: 1
            },
            param: {}
        };
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", "シート");
            this.$store.dispatch("operator_baseClick", "");

            this.code = this.$route.params.code;

            this.accountId = this.$store.state.operator.accountId;
            this.param = this.$store.state.operator.routeParam;

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                let param = {
                    sheetCode: this.code
                };
                let ress = await Vue.$cache.post('record/sheet/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.sendDt = ress.data.data;

                        if (((this.code == "") ||(this.code == "@")) && (this.param.edited == '1')) {
                            this.sendDt.useFlag = 1;
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
                
                if (this.param.edited == '1') {
                    this.editing = true;
                    if ((this.code == "") && (this.code == "@")) {
                        this.deleted = false;
                    } else if (this.code == "default") {
                        this.deleted = false;
                    } else {
                        this.deleted = true;
                    }
                } else {
                    this.editing = false;
                    this.deleted = false;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        saveDataPre() {
            if (this.chkRequired() == false) {
                return false;
            }

            if (!confirm("登録しますか？")) {
                return false;
            }

            this.saveData();
        },
        async saveData() {
            try {
                let param = {
                    data: this.sendDt,
                    updateId: this.accountId,
                    newFlag: ((this.code == "") || (this.code == "@")) ? 1 : 0
                };
                let ress = await Vue.$cache.post('record/sheet/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        if (ress.data.mess != "") {
                            alert(ress.data.mess);
                            return;
                        }
                        this.cancel();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        deleteDataPre() {
            const mess = "削除しますか？\n\nこの操作は元に戻せません。\nデータを残したい場合は「有効にする」のチェックを外して登録して下さい。";
            if (!confirm(mess)) {
                return false;
            }

            this.deleteData();
        },
        async deleteData() {
            try {
                let param = {
                    code: this.code,
                    updateId: this.accountId
                };
                let ress = await Vue.$cache.post('record/sheet/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.cancel();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        chkRequired() {
            if (this.sendDt.code == "") {
                alert("コードは省略できません");
                return false;
            }
            if (this.sendDt.name == "") {
                alert("名称は省略できません");
                return false;
            }
            if (this.sendDt.startDay == "") {
                alert("開始日は省略できません");
                return false;
            }
            return true;
        },
        editSrat() {
            this.editing = true;
            this.scrollTop(0);
        },
        cancel() {
            if (this.param.edited == '1') {
                this.$router.go(-1);
            } else {
                this.editing = false;
                this.scrollTop(0);
            }
        },
        scrollTop(v) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#sheetPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .info_area {

            .detail_area {

                .titlecell {
                    display: inline;
                    float: left;
                    clear: both;
                    width: 160px;
                    text-align: right;
                    padding-right: 20px;
                    font-weight: bold;
                    margin: 10px 0 8px;
                }

                .datacell {
                    display: inline;
                    float: left;
                    width: 360px;
                    margin: 8px 0;
                    border-bottom: 1px solid #CECECE;

                    .name, .kana {
                        width: 47%;

                        &:first-child {
                            margin-right: 6%;
                        }
                    }

                    .employNo {
                        width: 120px;
                    }

                    .email {
                        width: 100%;
                    }

                    .pwd {
                        width: 100%;
                    }

                    .authority {
                        width: 160px;
                        height: 27px;
                    }

                    input[type='text'], input[type='password'] {
                        /*width: 100%;*/
                        height: 27px;
                    }
                }
            }
        }

        .footer {
            clear: both;
            text-align: center;
            padding-top: 22px;

            .hensyu, .save, .cancel, .delete {
                width: 180px;
            }

            .cancel {
                margin-left: 20px;
            }

            .delete {
                margin-left: 20px;
                background-color: red;
                border: 2px solid red;
                color: white;
            }
        }
    }

    .button {
        padding: 3px 12px;
        font-weight: bold;
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:500px) {
    #sheetPg {
        .main_area {

            .info_area {

                .detail_area {

                    .titlecell {
                        float: none;
                        clear: none;
                        width: 160px;
                        text-align: left;
                        padding-right: 0;
                    }

                    .datacell {
                        display: block;
                        float: none;
                        width: 100%;
                        margin: 8px 0 12px;
                    }
                }
            }

            .footer {

                .cancel {
                    margin-left: 0;
                    margin-top: 22px;
                }

                .delete {
                    margin-left: 0;
                    margin-top: 22px;
                    background-color: red;
                    color: white;
                }
            }
        }
    }
}
</style>
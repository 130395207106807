<template>
    <div id="splashPg"></div>
</template>

<script>
import Vue from 'vue';
export default {
    name: "splashPg",
    data() {
        return {
            serverParam: null,
            splashTimer: 2000
        }
    },
    mounted() {
        this.$nextTick(function() {
            this.main();
        });
    },
    methods: {
        async main() {
            let r = await this.getSystemParam();
            if (this.serverParam == undefined) {
                return;
            } else if (this.serverParam.NoAccess == 1) {
                alert('メンテナンス中の為、現在は利用できません。\nしばらくして再度アクセスして下さい。');
                return;
            }

            let deviceCode = this.$cookies.get("DeviceCode") || '';
            let account = this.$cookies.get("Account") || '';

            if ((deviceCode == '') || (deviceCode == undefined)) {
                deviceCode = this.$uuid.v4().toUpperCase();
            }
            
            let setDate = Vue.$utils.getCookieDate(365);
            this.$cookies.set('DeviceCode', deviceCode, {expires: setDate});

            this.$store.commit("operator_deviceCode", deviceCode);

            r = await this.chkAccount(account, deviceCode);
        },
        async getSystemParam() {
            try {
                let ress = await Vue.$cache.get('system/param/', undefined, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        if ( ress.data.param != "" ) {
                            //--
                            this.serverParam = ress.data.param;
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        async chkAccount(account, deviceCode) {
            try {
                let param = {
					account: account,
					deviceCode: deviceCode
                };
                let ress = await Vue.$cache.post('account/authkey/', param, this.$store);
                if (ress.status == undefined) {
                    throw "ress:"+ress;
                }
                if (ress.status == 200) {
                    if (ress.data == undefined) {
                        throw "status:"+ress.status+" /data:"+ress.data;
                    }
                    if (ress.data.status == "ok") {
                        if (ress.data.data.checked == "1") {
                            this.$store.commit("operator_accountId", ress.data.data.accountId);
                            setTimeout(() => {
                                this.$router.push("/kintai/");
                            }, this.splashTimer);
                        } else {
                            setTimeout(() => {
                                this.$router.push("/login/");
                            }, this.splashTimer);
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        }
    }
}
</script>

<style lang="less">
#splashPg {
    background: url('../assets/logo.png') no-repeat center center;
	width: 100%;
    height: 100%;
}

@media (max-width: 479px) {
    #splashPg {
        background-size: 36%;
    }
}
</style>
<template>
    <div id="managedPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <div class="yearMonth"><span class="btnMoveMonthPre" @click.stop="mvMonth(-1)">＜</span><span class="year">{{activeYear}}年</span><div class="month">{{activeMonth}}月分</div><span class="btnMoveMonthNext" @click.stop="mvMonth(1)">＞</span></div>
                <button class="pdf_btn" @click.stop="pdfDownload()">PDFでダウンロード</button>&nbsp;<button class="csv_btn" @click.stop="csvDownloadOpen()">CSVでダウンロード</button>
                <form class="search_form" @submit.prevent="getData">
                    <div class="search_area">
                        <svg class="search-icon" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                        </svg>
                        <input type="text" v-model="searchVal" placeholder="社員の検索"/>
                    </div>
                </form>
            </div>
            <!-- 表 -->
            <table class="list">
                <tr class="list-header">
                    <td class="pdf"><input type="checkbox" v-model="allChecked"/></td>
                    <td class="employNo">社員番号</td>
                    <td class="name">氏名</td>
                    <td class="kakutei">確定</td>
                    <td class="syonin">承認</td>
                    <td class="syouka">有休消化</td>
                    <td class="zan">有休残</td>
                    <td class="hireDate">入社日</td>
                    <td class="sheetreki">シート</td>
                    <td class="workingdays">出勤日数</td>
                    <td></td>
                </tr>
                <mngData v-for="(data) in items" :key="data.employNo" :item="data" @changeChecked="changeChecked" @openRecord="openRecord" @openSheetReki="openSheetReki"></mngData>
            </table>
            <!-- フッター -->
            <div class="footer">

            </div>
        </div>
        <div v-if="csvWindow" id="managedCsvWindow">
            <div class="header_area">
                <div class="close-win" @click="close_win">✕</div>
            </div>
            <div class="detail_area">
                <div><span>時間単位：</span><select class="timeUnit_select" v-model="timeUnit"><option v-for="(item, idx) in timeUnits" v-bind:value="item.value" :key="idx">{{item.text}}</option></select></div>
            </div>
            <div class="footer_area">
                <button class="btnDownload" @click.stop="csvDownload">ダウンロード</button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import mngData  from '@/components/managedData.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "managedPg",
    mixins: [utilityMixin],
    components: {
        'mngData': mngData
    },
    data() {
        return {
            headerTitle: "出勤簿管理",
            items: [],
            activeYear: 0,
            activeMonth: 0,
            allChecked: false,
            isProcessing: false,
            searchVal: "",
            downloadFileName: "",
            csvWindow: false,
            timeUnit: "h",
            timeUnits: [
                {value:"h", text:"時（1.5）"},
                {value:"hm", text:"時:分（1:30）"},
                {value:"m", text:"分（90）"}
            ],
            ids: []
        }
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        allChecked(newValue, oldValue) {
            this.items.forEach(function(item){
                item.checked = newValue;
            });
        },
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            /*
            const now = new Date();

            if (this.activeYear == 0) {
                this.activeYear = now.getFullYear();
            }
            if (this.activeMonth == 0) {
                this.activeMonth = now.getMonth() + 1;
            }
            */

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                this.items = [];
                
                let param = {
					year: this.activeYear,
                    month: this.activeMonth,
                    search: this.searchVal
                };
                let ress = await Vue.$cache.post('managed/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        ress.data.data.list.forEach(function(item){
                            item.checked = false;
                        });
                        this.items = ress.data.data.list;
                        if (this.activeMonth == 0) {
                            this.activeYear = ress.data.data.year;
                            this.activeMonth = ress.data.data.month;
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        mvMonth(m) {
            this.activeMonth = this.activeMonth + m;
            this.allChecked = false;

            if (this.activeMonth > 12) {
                this.activeMonth = 1;
                ++this.activeYear;
            } else if (this.activeMonth < 1) {
                this.activeMonth = 12;
                --this.activeYear;
            }

            this.getData();
        },
        changeChecked(val) {
            let oncnt = 0;
            this.items.forEach(function(item){
                if (item.checked) {
                    oncnt += 1;
                }
            });
            if (oncnt == this.items.length) {
                this.allChecked = true;
            } else if (oncnt == 0) {
                this.allChecked = false;
            }
        },
        openRecord(uid) {
            this.$store.commit("operator_activeAccountId", uid);
            this.$store.commit("operator_activeYear", this.activeYear);
            this.$store.commit("operator_activeMonth", this.activeMonth);
            this.$store.commit("operator_activeSheet", "default");
            this.$store.commit("operator_manager", true);

            this.$router.push("/kintai/record", () => {});
        },
        openSheetReki(uid) {
            this.$store.commit("operator_activeAccountId", uid);
            this.$router.push("/kintai/sheetreki", () => {});
        },
        async pdfDownload() {
            try {
                this.ids = [];
                let name = "";
                let _self = this;
                this.items.forEach(function(item){
                    if (item.checked == true) {
                        let val = {'employNo':item.employNo};
                        _self.ids.push(val);
                        if (name == "") {
                            name = item.name.replace(/\s+/g, "");
                        }
                    }
                });
                if (this.ids.length < 1) {
                    return;
                }
                
                if (this.ids.length == 1) {
                    this.downloadFileName = "出勤簿_"+this.activeYear+"年"+this.activeMonth+"月_"+name+".pdf"
                } else {
                    this.downloadFileName = "出勤簿_"+this.activeYear+"年"+this.activeMonth+"月.pdf"
                }

                let param = {
                    accountIds: this.ids,
                    year: this.activeYear,
                    month: this.activeMonth
                };

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'blob',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };
                
                let ress = await Vue.$cache.post('record/pdfs/', param, this.$store, request);
                
                if (ress.status == 200) {
                    const url = URL.createObjectURL(new Blob([ress.data]));

                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', this.downloadFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);
                    
                    /*
                    if (ress.data.status == "ok") {
                        //--
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                    */
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        csvDownloadOpen() {
            this.ids = [];
            let name = "";
            let _self = this;
            this.items.forEach(function(item){
                if (item.checked == true) {
                    let val = {'employNo':item.employNo};
                    _self.ids.push(val);
                    if (name == "") {
                        name = item.name.replace(/\s+/g, "");
                    }
                }
            });
            if (this.ids.length < 1) {
                return;
            }

            if (this.ids.length == 1) {
                this.downloadFileName = "出勤簿_"+this.activeYear+"年"+this.activeMonth+"月_"+name+".csv"
            } else {
                this.downloadFileName = "出勤簿_"+this.activeYear+"年"+this.activeMonth+"月.csv"
            }

            this.csvWindow = true;
            this.$store.dispatch("operator_backPanel", "on");
        },
        async csvDownload() {
            try {
                let param = {
                    accountIds: this.ids,
                    year: this.activeYear,
                    month: this.activeMonth,
                    unit: this.timeUnit
                };

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'blob',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };
                
                let ress = await Vue.$cache.post('record/csvs/', param, this.$store, request);

                if (ress.status == 200) {
                    let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
					let blob = new Blob([bom, ress.data], {type: 'text/csv'});
					const url = (window.URL || window.webkitURL).createObjectURL(blob);
                    
                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', this.downloadFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);

                    this.close_win();
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        close_win() {
            this.csvWindow = false;
            this.$store.dispatch("operator_backPanel", "");
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#managedPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .header {
            height: 42px;
            
            .yearMonth {
                width: 100%;
                font-size: 1.3rem;
                font-weight: bold;
                display: inline;

                .btnMoveMonthPre {
                    margin-right: 8px;
                    cursor: pointer;
                }

                .btnMoveMonthNext {
                    margin-left: 8px;
                    cursor: pointer;
                }

                .year {
                    margin-right: 16px;
                }

                .month {
                    width: 72px;
                    text-align: right;
                    display: inline-block;
                }
            }

            .pdf_btn {
                padding: 0 6px;
                margin-left: 32px;
            }

            .csv_btn {
                padding: 0 6px;
                margin-left: 8px;
            }

            .search_form {
                margin: 0;
                display: inline;
            }

            .search_area {
                position: relative;
                width: 280px;
                float: right;
                border: 1px solid #000;
                border-radius: 17px;
                padding: 6px 12px 6px 30px;

                .search-icon {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 6px;
                    margin: auto;
                    fill: #777777;
                }
                
                input[type=text] {
                    width: 100%;
                    border: 0;

                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
            }
        }

        .list {
            width: 100%;
            border-spacing: 0;

            .list-header {
                .pdf {
                    width: 36px;
                }
                .employNo {
                    width: 80px;
                }
                .name {
                    width: 160px;
                }
                .kakutei {
                    width: 48px;
                }
                .syonin {
                    width: 48px;
                }
                .syouka, .zan {
                    width: 80px;
                }
                .hireDate {
                    width: 110px;
                }
                .sheetreki {
                    width: 48px;
                }
                .workingdays {
                    width: 48px;
                }
            }

            .list-header {
                background-color: var(--gridFixedBackColor);
                font-weight: bold;
                color: white;

                td {
                    border-bottom: 1px solid white;
                    border-bottom-color: var(--gridFixedBorderColor);
                    border-left: 1px solid white;
                    border-left-color: var(--gridFixedBorderColor);
                    padding: 6px 4px;
                    text-align: center;
                    white-space: nowrap;

                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }
    }

    #managedCsvWindow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 380px;
        height: 420px;
        margin: auto;
        box-shadow: 3px 3px 8px 1px rgba(0,0,0,0.2);
        background-color: var(--backColor);
        border: 1px solid #acacac;
        border-radius: 8px;
        z-index: 99991;

        .header_area {
            .close-win {
                float: right;
                width: 32px;
                height: 32px;
                text-align: center;
                padding: 6px 0;
                cursor: pointer;
            }
        }

        .detail_area {
            margin-top: 32px;
            padding: 12px 20px;
        }

        .footer_area {
            position: absolute;
            bottom: 24px;
            width: 100%;
            text-align: center;

            .btnDownload {
                padding: 4px 12px;
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:1027px) {
    #managedPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;

            .header {
                width: 1028px;
            }

            .list {
                width: 1028px;
            }

            .footer {
                width: 1028px;
            }
        }
    }
}
</style>
<template>
    <div id="LeaveApprovalPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header"></div>

            <div v-if="list" class="info_area">
                <div>
                    <label><input type="radio" v-model="approvedMode" value="unapproved" @change.stop="changeApprovedMode"/>未承認</label>
                    <label><input type="radio" v-model="approvedMode" value="period" @change.stop="changeApprovedMode"/>期間指定</label>
                    <div class="target_area">
                        <div v-if="selectArea">
                            <select class="selectYear" v-model="year" @change="change_year">
                                <option v-for="(y, i) in years" :key="i" :value="y">{{y}}年</option>
                            </select>
                            <select class="selectMonth" v-model="month" @change="change_month">
                                <option value="1">1月</option>
                                <option value="2">2月</option>
                                <option value="3">3月</option>
                                <option value="4">4月</option>
                                <option value="5">5月</option>
                                <option value="6">6月</option>
                                <option value="7">7月</option>
                                <option value="8">8月</option>
                                <option value="9">9月</option>
                                <option value="10">10月</option>
                                <option value="11">11月</option>
                                <option value="12">12月</option>
                            </select>&nbsp;
                            <select class="selectMode" v-model="approved" @change="change_selectMode">
                                <option value="">&nbsp;</option>
                                <option value="unapproved">未承認</option>
                                <option value="approved">承認済</option>
                            </select>
                        </div>
                    </div>
                    <form class="search_form" @submit.prevent="getData">
                        <div class="search_area">
                            <svg class="search-icon" viewBox="0 0 24 24" width="24" height="24">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                            </svg>
                            <input type="text" v-model="searchVal" placeholder="社員の検索"/>
                        </div>
                    </form>
                </div>
                <table class="list">
                    <tr class="list-header">
                        <td class="employNo">社員番号</td>
                        <td class="name">氏名</td>
                        <td class="leaveName">休暇種類</td>
                        <td class="applydate">申請日時</td>
                        <td class="start">開始</td>
                        <td class="end">終了</td>
                        <td class="status">状態</td>
                    </tr>
                    <mngData v-for="(item, idx) in items" :key="idx" :item="item" :accountId="accountId" :specialleaveList="specialleaveList" @leaave_click="leaave_click"></mngData>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import mngData from '@/components/leaveMngData.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "LeaveApprovalPg",
    mixins: [utilityMixin],
    components: {
        'mngData': mngData,
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        approvedMode(n, o) {
            this.selectArea = (n == "unapproved") ? false : true;
            this.getData();
        },
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            accountId: "",
            specialleaveList: [],
            isActive: true,
            list: true,
            items: [],
            searchVal: "",
            years: [],
            year: "",
            month: "",
            approved: "",
            approvedMode: "unapproved",
            selectArea: false,
            dates:[],
            activeYear: 0,
            activeMonth: 0,
            holidays: [],
        }
    },

    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", "休暇承認");
            this.$store.dispatch("operator_baseClick", "");

            this.accountId = this.$store.state.operator.accountId;

            let now = new Date();
            let yy = now.getFullYear();
            let yy2 = yy;
            this.years.push(yy);
            for (let y=1; y<5; y++) {
                yy2 -= 1;
                this.years.push(yy2);
            }
            this.year = yy;
            this.month = now.getMonth() + 1;

            this.getSettingData();
        });
    },
    methods: {
        async getSettingData() {
            try {
                const ress = await Vue.$cache.get('leave/setting/', undefined, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.specialleaveList = ress.data.data.specialleaveList;
                        this.getData();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        async getData() {
            try {
                this.items = [];

                let searchMonth = ""
                if (this.approvedMode != "unapproved") {
                    searchMonth = this.year + "-" + String(100 + (this.month - 0)).substring(1, 3)
                }
                
                let param = {
                    accountId: this.accountId,
                    searchMonth: searchMonth,
                    searchStaff: this.searchVal,
                    searchMode: this.approved
                };
                
                let ress = await Vue.$cache.post('leave/syoninlist/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        ress.data.data.list.forEach((item) => {
                            let data = {};
                            data.leaveId = item.leaveId;
                            data.employNo = item.employNo;
                            data.name = item.name;
                            data.start = item.startDate;
                            if (item.startTime != "") {
                                data.start = data.start + " " + item.startTime.split(":")[0] + ":" + item.startTime.split(":")[1];
                            }
                            data.end = item.endDate;
                            if (item.leaveTimes != "") {
                                data.end = item.leaveTimes + " 時間";
                            }
                            data.leaveType = item.leaveType + "";
                            data.specialleave = item.specialleave;
                            data.applydate = item.requestDateTime;
                            data.comment = item.comment;
                            data.syoninDate = item.syoninDate;
                            data.status = item.status + "";
                            data.timestamp = item.timestamp;
                            this.items.push(data);
                        });
                        console.log(this.items);
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        changeApprovedMode() {
            
        },
        leaave_click(leaveId) {
            let url = "leaveConfirmation?leaveId=" + leaveId;
            this.$router.push(url, () => {});
        },
        change_year() {
            this.getData();
        },
        change_month() {
            this.getData();
        },
        change_selectMode() {
            this.getData();
        },
        base_click() {
            //--
        },
    }
}
</script>

<style lang="less">
#LeaveApprovalPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .header {
            text-align: center;
            margin-bottom: 15px;

            .leftSpace {
                width: 220px;
                margin-top: 10px;
                user-select: none;

                .yearMonth {
                    width: 100%;
                    height: 30px;
                    font-size: 1.3rem;
                    font-weight: bold;

                    .btnMoveMonthPre {
                        margin-right: 8px;
                        cursor: pointer;
                    }

                    .btnMoveMonthNext {
                        margin-left: 8px;
                        cursor: pointer;
                    }

                    .year {
                        margin-right: 16px;
                    }

                    .month {
                        width: 72px;
                        text-align: right;
                        display: inline-block;
                    }
                }
            }

            button {
                font-size: 13px;
                font-weight: bold;
                height: 30px;
                margin: auto;
                width: 150px;
                color: #000;
                border: 1px solid #ddd;
            }

            .active {
                background:#ff3;
                font-size: 16px;
                width: 152px;
                height: 32px;
            }
        }

        .info_area {
            .target_area {
                display: inline-block;
                padding: 0 12px;

                .selectMonth {
                    margin-left: 4px;
                }
            }

            .search_form {
                margin: 0;
                display: inline;
            }

            .search_area {
                position: relative;
                width: 280px;
                float: right;
                border: 1px solid #000;
                border-radius: 17px;
                padding: 6px 12px 6px 30px;

                .search-icon {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 6px;
                    margin: auto;
                    fill: #777;
                }

                input[type=text] {
                    width: 100%;
                    border: 0;

                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
            }

            label {
                line-height: 40px;
                padding: 0 12px;
            }

            .list {
                width: 100%;
                border-spacing: 0;

                .list-header {
                    background-color: var(--gridFixedBackColor);
                    font-weight: bold;
                    color: white;

                    .name,
                    .leaveName,
                    .start,
                    .end {
                        width: 160px;
                    }

                    .employNo,
                    .status {
                        width: 90px;
                    }

                    td {
                        border-bottom: 1px solid white;
                        border-bottom-color: var(--gridFixedBorderColor);
                        border-left: 1px solid white;
                        border-left-color: var(--gridFixedBorderColor);
                        padding: 6px 4px;
                        text-align: center;
                        white-space: nowrap;

                        &:first-child {
                            border-left: 0;
                        }
                    }
                }
            }
        }

        .footer {
            clear: both;
            text-align: center;
            padding-top: 22px;
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:500px) {
    #LeaveApprovalPg {
        .main_area {

            .info_area {

                .search_area  {
                    margin-bottom: 10px;
                    width: 200px;
                }

                label {
                    line-height: 40px;
                    padding: 0 2px;
                }
            }
        }
    }
}
</style>
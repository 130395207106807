<template>
    <div id="applyGoodsPaymentEdit">
        <div class="backBtn" @click.stop="backPage()"><icon name="chevron-left" class="icon"></icon> 一覧</div>
        <div class="header"><div class="date_area">申請日：<input type="date" v-model="date"/></div><div class="total_area">合計：<span class="total">{{getAmount(total)}}</span><span class="unit">円</span></div></div>
        <div class="detail_list">
            <item class="detail" v-for="(dat, ix) in details" :key="ix"
                :payDate="dat.payDate"
                :receiptName="dat.receiptName"
                :itemName="dat.itemName"
                :from="dat.from"
                :to="dat.to"
                :money="getMoney(dat.money)"
                @paydate_change="paydate_change($event, ix)"
                @receiptName_change="receiptName_change($event, ix)"
                @itemName_change="itemName_change($event, ix)"
                @change_money="change_money($event, ix)"
            ></item>
            <div class="button_area"><button @click.stop="save_goods">保存</button></div>
            <div v-if="!checkNewData" class="button_area"><button class="pdf_print" @click.stop="downloadBtn()">PDF出力</button></div>
            <div v-if="!checkNewData" class="button_area deleteBtn"><button @click.stop="delete_goods">削除</button></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import item from '@/components/goodsPaymentEdit.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    mixins: [utilityMixin],
    data() {
        return {
            headerTitle: "交通費精算",
            accountId: "",
            activeAccountId: "",
            userName: "",
            id: "",
            mode: "",
            date: "",
            total: null,
            details: [],
            editedFlag: false
        };
    },
    components: {
        'item': item
    },
    computed: {
        checkNewData() {
            if (this.id != "" && this.id != undefined) {
                return false;
            } else {
                return true;
            }
        },
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            //--
        }
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;

            this.mode = this.$route.params.mode;
            this.id = this.$route.params.id;
            
            this.itemClear();
            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                this.details = [];
                let param = {
                    accountId: this.activeAccountId,
                    id: this.id
                };
                let ress = await Vue.$cache.post('payment/goods/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.date = ress.data.data.date;
                        this.total = ress.data.data.total;
                        this.userName = ress.data.data.userName;
                        for (let p = 0; p < ress.data.data.detail.length; p++) {
                            let dat = ress.data.data.detail[p];
                            this.details.push(dat);
                        }
                        this.details.push({});
                        if (this.mode == "new") {
                            this.id = "";
                            let now = new Date();
                            this.date = now.toChar("yyyy-MM-dd");
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        save_goods() {
            if (this.valdate()) {
                this.setData();
            }
        },
        async setData() {
            try {
                let param = {
                    accountId: this.activeAccountId,
                    id: this.id,
                    date: this.date,
                    detail: this.details.filter((x) => x.payDate != undefined)
                };
                console.log(param.detail);
                let ress = await Vue.$cache.post('payment/goods/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.id = ress.data.data.id;
                        this.mode = "edit";
                        alert("更新されました");
                        setTimeout(() => {
                            this.jump_pg("/kintai/applypayment/goods/edit/"+this.id);
                        }, 0)
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        delete_goods() {
            if (confirm("指定のデータを削除しますか？") == false) {
                return;
            }
            this.deleteGoods();
        },
        async deleteGoods() {
            try {
                let param = {
                    accountId: this.activeAccountId,
                    id: this.id
                };
                let ress = await Vue.$cache.post('payment/goods/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.backPage();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        downloadBtn() {
            this.downloadPDF();
        },
        async downloadPDF() {
            try {
                let name = this.userName.replace(/\s+/g, "");
                let pdfFileName = this.date.replace(/-/g,"")+"_"+this.total+"_交通費精算_"+name+".pdf"
                let param = {
                    accountId: this.activeAccountId,
                    id: this.id,
                    applyMode: "goods"
                };

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'blob',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };

                let ress = await Vue.$cache.post('payment/goods/pdf/', param, this.$store, request);
                if (ress.status == 200) {
                    const url = URL.createObjectURL(new Blob([ress.data]));

                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', pdfFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        valdate() {
            //== 申請日の有無
            if (this.date == "" || this.date == undefined) {
                alert("申請日は省略出来ません");
                return false;
            }

            if (this.details == undefined || this.details.length == 0) {
                alert("内容が不明です");
                return false;
            }

            let notValidate = false;
            let cnt = 0;
            for (let p = 0; p < this.details.length; p++) {
                let data = this.details[p];
                if (data.payDate != undefined) {
                    if (data.payDate == "") {
                        alert("領収日は省略出来ません");
                        notValidate = true;
                        break;
                    }
                    if (data.receiptName == "" || data.receiptName == undefined) {
                        alert("領収先は省略出来ません");
                        notValidate = true;
                        break;
                    }
                    if (data.itemName == "" || data.itemName == undefined) {
                        alert("品目は省略出来ません");
                        notValidate = true;
                        break;
                    }
                    if (data.money == "" || data.money == undefined) {
                        alert("金額は省略出来ません");
                        notValidate = true;
                        break;
                    }
                } else {
                    ++cnt;
                }
            }

            if (cnt == this.details.length) {
                alert("内容が不明です");
                return false;
            }

            if (notValidate) {
                return false;
            }

            return true;
        },
        calcTotal() {
            let total = null;
            this.details.forEach((x) => {
                if (x.money != "" && x.money != undefined) {
                    total += (x.money - 0);
                }
            });
            this.total = total;
        },
        paydate_change(m, idx) {
            this.details[idx].payDate = m;
            this.cleanNode();
        },
        receiptName_change(m, idx) {
            this.details[idx].receiptName = m;
            this.cleanNode();
        },
        itemName_change(m, idx) {
            this.details[idx].itemName = m;
            this.cleanNode();
        },
        change_money(m, idx) {
            this.details[idx].money = m;
            this.cleanNode();
            this.calcTotal();
        },
        itemClear() {
            let now = new Date();
            this.date = now.toChar("yyyy-MM-dd");
            this.details = [];
        },
        cleanNode() {
            let details = this.details.filter((x) => {
                return !this.checkEmptyNode(x);
            });
            this.details = details;
            this.details.push({});
        },
        checkEmptyNode(node) {
            if (node.payDate != "" && node.payDate != undefined) {
                return false;
            }
            if (node.receiptName != "" && node.receiptName != undefined) {
                return false;
            }
            if (node.itemName != "" && node.itemName != undefined) {
                return false;
            }
            if (node.money != "" && node.money != undefined) {
                return false;
            }
            return true;
        },
        getMoney(val) {
            if (val == "" || val == undefined) {
                return "";
            } else {
                return val.toString(10);
            }
        },
        getAmount(val) {
            return Vue.$utils.convertCurrency(val);
        },
        backPage() {
            if (this.editedFlag) {
                if (confirm("保存していないデータがあります。\n\n破棄して戻りますか？") == false) {
                    return;
                }
            }
            this.jump_pg("/kintai/applypayment/goods");
        },
        jump_pg(key, param) {
            this.paging += 1;
            if (param != undefined) {
                this.$store.commit("operator_routeParam", param);
            }
            this.$router.push(key, () => {});
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less" scoped>
#applyGoodsPaymentEdit {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: min-content auto;

    .header {
        display: grid;
        grid-template-columns: 180px auto;
        padding-bottom: 6px;
        border-bottom: 1px solid var(--grayoutBackColor);

        .date_area {
            display: flex;
            justify-content: left;
            align-items: flex-end;

            input {
                width: 114px;
                height: 28px;
            }
        }

        .total_area {
            text-align: right;

            .total {
                font-size: 1.6rem;
                font-weight: bold;
            }
        }
    }

    .detail_list {
        overflow: auto;

        .detail {
            margin-top: 8px;
        }

        .button_area {
            margin-top: 22px;
            width: 100%;
            text-align: center;

            button {
                width: calc(100% - 42px);
                letter-spacing: 20px;
                text-indent: 20px;
                padding-top: 3px;
                padding-bottom: 3px;
            }

            .pdf_print {
                letter-spacing: 4px;
                text-indent: 4px;
            }
        }

        .deleteBtn {
            button {
                background-color: red;
                border: 2px solid red;
                color: white;
            }
        }
    }

    .downloadBtn {
        position: absolute;
        top: 58px;
        right: 12px;

        button {
            padding: 0 6px;
        }
    }

    .backBtn {
        position: absolute;
        top: 58px;
        left: 12px;
        cursor: pointer;
        color: #42a4ff;

        .icon {
            vertical-align: text-top;
        }
    }

    .icon {
        fill: #42a4ff;
    }
}
</style>
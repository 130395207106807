<template>
    <div id="workSituationPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <div class="yearMonth"><span class="btnMoveMonthPre" @click.stop="mvMonth(-1)">＜</span><span class="year">{{activeYear}}年</span><div class="month">{{activeMonth}}月分</div><span class="btnMoveMonthNext" @click.stop="mvMonth(1)">＞</span></div>
                <form class="search_form" @submit.prevent="getData">
                    <div class="search_area">
                        <svg class="search-icon" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                        </svg>
                        <input type="text" v-model="searchVal" placeholder="社員の検索"/>
                    </div>
                </form>
            </div>
            <!-- 表 -->
            <table class="list">
                <tr class="list-header">
                    <td class="employNo">社員番号</td>
                    <td class="name">氏名</td>
                    <td class="workDays">出勤日数</td>
                    <td class="now_zan">当月<br>残業時間</td>
                    <td class="pre_zan">{{preMonth}}月<br>残業時間</td>
                    <td class="pre2_zan">{{pre2Month}}月<br>残業時間</td>
                    <td class="pre2_zan">{{pre3Month}}月<br>残業時間</td>
                    <td class="zan">年間<br>残業時間</td>
                    <td class="yukyu">有給休暇<br><span class="sub_small">消化数 / 残数</span></td>
                    <td class="syoubyou">傷病休暇<br>取得日数</td>
                    <td class="seiri">生理休暇<br>当月日数</td>
                    <td></td>
                </tr>
                <mngData v-for="(data) in items" :key="data.employNo" :item="data"></mngData>
            </table>
            <!-- フッター -->
            <div class="footer">

            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import mngData  from '@/components/workSituationData.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "workSituationPg",
    mixins: [utilityMixin],
    components: {
        'mngData': mngData
    },
    data() {
        return {
            headerTitle: "勤務状況",
            items: [],
            activeYear: 0,
            activeMonth: 0,
            searchVal: ""
        }
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        },
        preMonth() {
            let m = this.activeMonth - 1;
            if (m < 1) m = 12;
            return m
        },
        pre2Month() {
            let m = this.activeMonth - 2;
            if (m < 1) m = 12 + m;
            return m
        },
        pre3Month() {
            let m = this.activeMonth - 3;
            if (m < 1) m = 12 + m;
            return m
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            const now = new Date();

            if (this.activeYear == 0) {
                this.activeYear = now.getFullYear();
            }
            if (this.activeMonth == 0) {
                this.activeMonth = now.getMonth() + 1;
            }

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                this.items = [];
                
                let param = {
					year: this.activeYear,
                    month: this.activeMonth,
                    search: this.searchVal
                };
                let ress = await Vue.$cache.post('worksituation/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.items = ress.data.data.list;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        mvMonth(m) {
            this.activeMonth = this.activeMonth + m;
            this.allChecked = false;

            if (this.activeMonth > 12) {
                this.activeMonth = 1;
                ++this.activeYear;
            } else if (this.activeMonth < 1) {
                this.activeMonth = 12;
                --this.activeYear;
            }

            this.getData();
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#workSituationPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .header {
            height: 42px;
            
            .yearMonth {
                width: 100%;
                font-size: 1.3rem;
                font-weight: bold;
                display: inline;

                .btnMoveMonthPre {
                    margin-right: 8px;
                    cursor: pointer;
                }

                .btnMoveMonthNext {
                    margin-left: 8px;
                    cursor: pointer;
                }

                .year {
                    margin-right: 16px;
                }

                .month {
                    width: 72px;
                    text-align: right;
                    display: inline-block;
                }
            }

            .search_form {
                margin: 0;
                display: inline;
            }

            .search_area {
                position: relative;
                width: 280px;
                float: right;
                border: 1px solid #000;
                border-radius: 17px;
                padding: 6px 12px 6px 30px;

                .search-icon {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 6px;
                    margin: auto;
                    fill: #777777;
                }
                
                input[type=text] {
                    width: 100%;
                    border: 0;

                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
            }
        }

        .list {
            width: 100%;
            border-spacing: 0;

            .list-header {
                .employNo {
                    width: 80px;
                }
                .name {
                    width: 160px;
                }
                .workDays, .now_zan, .pre_zan, .pre2_zan, .zan {
                    width: 48px;
                }
                .yukyu, .syoubyou, .seiri {
                    width: 48px;
                }
            }

            .list-header {
                background-color: var(--gridFixedBackColor);
                font-weight: bold;
                color: white;

                td {
                    border-bottom: 1px solid white;
                    border-bottom-color: var(--gridFixedBorderColor);
                    border-left: 1px solid white;
                    border-left-color: var(--gridFixedBorderColor);
                    padding: 6px 4px;
                    text-align: center;
                    white-space: nowrap;

                    &:first-child {
                        border-left: 0;
                    }

                    .sub_small {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:1027px) {
    #workSituationPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;

            .header {
                width: 1028px;
            }

            .list {
                width: 1028px;
            }

            .footer {
                width: 1028px;
            }
        }
    }
}
</style>
<template>
    <div id="LeaveConfirmationPg">
        <div class="dummy"></div>
        <div class="cards_area" :key="dummyKey">
            <card class="card"
                :accountId="accountId"
                :leaveId="leaveId"
                :timestamp="timestamp"
                :start="start"
                :end="end"
                :leaveType="leaveType"
                :specialleave="specialleave"
                :comment="comment"
                :remandComment="remandComment"
                :applydate="applydate"
                :status="status"
                :leaveTypeList="leaveTypeList"
                :specialleaveList="specialleaveList"
                :zanYukyu="zanYukyu"
                :select="false"
                :visible="true"
                :approval="true"
                :top="40"
                :zindex="9990"
                @card_update="card_update($event)">
            </card>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import card from '@/components/leave-card.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "LeaveConfirmationPg",
    mixins: [utilityMixin],
    components: {
        'card': card,
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        },
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            accountId: "",
            leaveTypeList: [
                {name: "有給休暇", code: 1},
                {name: "特別休暇", code: 2}
            ],
            specialleaveList: [],
            leaveId: "",
            zanYukyu: "",
            timestamp: "",
            start: "",
            end: "",
            leaveType: "",
            specialleave: "",
            comment: "",
            remandComment: "",
            applydate: "",
            status: "",
            dummyKey: "dkey0",
            dummyKeyNum: 0
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", "休暇承認");
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;

            this.leaveId = this.$route.query.leaveId;

            this.getSettingData();
        });
    },
    methods: {
        async getSettingData() {
            try {
                const ress = await Vue.$cache.get('leave/setting/', undefined, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.specialleaveList = ress.data.data.specialleaveList;
                        this.dummyKeyNum += 1;
                        this.dummyKey = "dkey" + this.dummyKeyNum;

                        this.getCard();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        async getCard() {
            try {
                const param = {
                    leaveId: this.leaveId
                };
                const ress = await Vue.$cache.post('leave/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        let zan = ress.data.data.yukyuZanCnt + "";
                        let day = zan.split(".")[0] - 0;
                        this.zanYukyu = day + "日";
                        if (zan.split(".").length > 1) {
                            let whm = zan - day;
                            let wt = 28800000 * whm;
                            let dd = new Date();
                            dd.setTime(wt - 32400000);
                            let h = dd.getHours();
                            let m = dd.getMinutes();
                            h = h + (m / 60);
                            this.zanYukyu = this.zanYukyu + "と" + h + "時間";
                        }

                        const item = JSON.parse(JSON.stringify(ress.data.data));

                        this.start = item.startDate;
                        if (item.startTime != "") {
                            this.start = this.start + " " + item.startTime.split(":")[0] + ":" + item.startTime.split(":")[1];
                        }
                        this.end = item.endDate;
                        if (item.leaveTimes != "") {
                            this.end = item.leaveTimes + "";
                        }
                        this.leaveType = item.leaveType + "";
                        this.specialleave = item.specialleave;
                        this.applydate = item.requestDateTime;
                        this.comment = item.comment;
                        this.remandComment = item.remandComment;
                        this.status = item.status + "";
                        this.timestamp = item.timestamp;
                        console.log(item);
                        this.dummyKeyNum += 1;
                        this.dummyKey = "dkey" + this.dummyKeyNum;

                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
            
        },
        card_update(e) {
            this.jump_pg('/kintai/leaveApproval');
        },
        jump_pg(key) {
            this.$router.push(key, () => {});
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#LeaveConfirmationPg {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50px auto;

    .cards_area {
        position: relative;
        width: 100%;
        min-height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column-reverse;
    }
}

@media(max-width:500px) {
    #LeaveConfirmationPg {
        /*  */
    }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue'
import Splash from '@/views/splash.vue'
import LoginPg from '@/views/login.vue'
import AccountPg from '@/views/account.vue'
import RecordPg from '@/views/record.vue'
import ManagedPg from '@/views/managed.vue'
import WorkSituationPg from '@/views/workSituation.vue'
import SheetRekiPg from '@/views/sheetreki.vue'
import AcManagedPg from '@/views/account_managed.vue'
import SheetmngPg from '@/views/sheet_managed.vue'
import SheetPg from '@/views/sheet.vue'
import HolidaysPg from '@/views/holidays.vue'
import StampingPg from '@/views/stamping.vue'
import ApplyPaymentPg from '@/views/apply_payment.vue'
import ApplyPaymentTop from '@/views/apply_payment_top.vue'
import ApplyTrafficPg from '@/views/apply_payment_traffic.vue'
import ApplyTrafficEdit from '@/views/apply_payment_traffic_edit.vue'
import ApplyGoodsPg from '@/views/apply_payment_goods.vue'
import ApplyGoodsEdit from '@/views/apply_payment_goods_edit.vue'
import ApplyNearTravelPg from '@/views/apply_payment_nearTravel.vue'
import ApplyTravelPg from '@/views/apply_payment_travel.vue'
import ApplyTravelEdit from '@/views/apply_payment_travel_edit.vue'
import PaymentGoodsPg from '@/views/paymentGoods.vue'
import ApplyLeavePg from '@/views/apply_leave.vue'
import SubstituteHolidayPg from '@/views/substituteHoliday.vue'
import LeaveApprovalPg from '@/views/leaveApproval.vue'
import LeaveConfirmationPg from '@/views/leaveConfirmation.vue'
import PayslipPg from '@/views/payslip.vue'
import ConfigPg from '@/views/config.vue'
import SamplePg from '@/views/sample.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/kintai',
    component: Index,
    children: [
      {
        path: '',
        name: 'stampingPg',
        component: StampingPg
      },
      {
        path: 'applyleave',
        name: 'applyLeavePg',
        component: ApplyLeavePg
      },
      {
        path: 'substituteHoliday',
        name: 'substituteHolidayPg',
        component: SubstituteHolidayPg
      },
      {
        path: 'record',
        name: 'recordPg',
        component: RecordPg
      },
      {
        path: 'applypayment',
        component: ApplyPaymentTop,
        children: [
          {
            path: '',
            name: 'applyPaymentPg',
            component: ApplyPaymentPg
          },
          {
            path: 'traffic',
            name: 'traffic',
            component: ApplyTrafficPg
          },
          {
            path: 'traffic/:mode/:id',
            name: 'traffic_edit',
            component: ApplyTrafficEdit
          },
          {
            path: 'goods',
            name: 'goods',
            component: ApplyGoodsPg
          },
          {
            path: 'goods/:mode/:id',
            name: 'goods_edit',
            component: ApplyGoodsEdit
          },
          {
            path: 'nearTravel',
            name: 'nearTravel',
            component: ApplyNearTravelPg
          },
          {
            path: 'nearTravel/:mode/:id',
            name: 'nearTravel_edit',
            component: ApplyTravelEdit
          },
          {
            path: 'travel',
            name: 'travel',
            component: ApplyTravelPg
          },
          {
            path: 'travel/:mode/:id',
            name: 'travel_edit',
            component: ApplyTravelEdit
          }
        ]
      },
      {
        path: 'leaveApproval',
        name: 'leaveApprovalPg',
        component: LeaveApprovalPg
      },
      {
        path: 'leaveConfirmation',
        name: 'leaveConfirmationPg',
        component: LeaveConfirmationPg
      },
      {
        path: 'payslip',
        name: 'payslipPg',
        component: PayslipPg
      },
      {
        path: 'managed',
        name: 'managedPg',
        component: ManagedPg
      },
      {
        path: 'workSituation',
        name: 'workSituationPg',
        component: WorkSituationPg
      },
      {
        path: 'sheetreki',
        name: 'sheetRekiPg',
        component: SheetRekiPg
      },
      {
        path: 'sheetmng',
        name: 'sheetmngPg',
        component: SheetmngPg
      },
      {
        path: 'sheet/:code',
        name: 'sheetPg',
        component: SheetPg
      },
      {
        path: 'holidays/:code',
        name: 'holidaysPg',
        component: HolidaysPg
      },
      {
        path: 'config',
        name: 'configPg',
        component: ConfigPg
      },
      {
        path: 'applypayments/goods',
        name: 'paymentGoodsPg',
        component: PaymentGoodsPg
      }
    ]
  },
  {
    path: '/',
    name: 'splash',
    component: Splash
  },
  {
    path: '/login',
    name: 'loginPg',
    component: LoginPg
  },
  {
    path: '/account',
    component: Index,
    children: [
      {
        path: '',
        name: 'accountPg',
        component: AccountPg
      },
      {
        path: 'managed',
        name: 'acmanagedPg',
        component: AcManagedPg
      }
    ]
  },
  {
    path: '/develop',
    component: Index,
    children: [
      {
        path: 'ui-sample',
        name: 'samplePg',
        component: SamplePg
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

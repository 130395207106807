<template>
    <tr class="managed-row">
        <td class="employNo">{{cvtEmploy(item.employNo)}}</td>
        <td class="name">{{item.name}}</td>
        <td class="workDays">{{item.days}}</td>
        <td class="now_zan" :class="{'zanlow':(zanLevel(item.zan1) == 1), 'zanheight':(zanLevel(item.zan1) == 2), 'zanMax':(zanLevel(item.zan1) == 3)}">{{times(item.zan1)}}</td>
        <td class="pre_zan" :class="{'zanlow':(zanLevel(item.zan2) == 1), 'zanheight':(zanLevel(item.zan2) == 2), 'zanMax':(zanLevel(item.zan2) == 3)}">{{times(item.zan2)}}</td>
        <td class="pre2_zan" :class="{'zanlow':(zanLevel(item.zan3) == 1), 'zanheight':(zanLevel(item.zan3) == 2), 'zanMax':(zanLevel(item.zan3) == 3)}">{{times(item.zan3)}}</td>
        <td class="pre3_zan" :class="{'zanlow':(zanLevel(item.zan4) == 1), 'zanheight':(zanLevel(item.zan4) == 2), 'zanMax':(zanLevel(item.zan4) == 3)}">{{times(item.zan4)}}</td>
        <td class="zan" :class="{'zanlow':(zanTotalLevel(item.zantotal) == 1), 'zanheight':(zanTotalLevel(item.zantotal) == 2), 'zanMax':(zanTotalLevel(item.zantotal) == 3)}">{{times(item.zantotal)}}</td>
        <td class="yukyu">{{item.yukyu}}</td>
        <td class="syoubyou">{{item.syoubyou}}</td>
        <td class="seiri">{{item.seiri}}</td>
        <td></td>
    </tr>
</template>

<script>
export default {
    props: {
        item: null
    },
    methods: {
        cvtEmploy(val) {
            if (val == 0) {
                val = "0001";
            } else {
                val = ((1000 + (val - 0)) + "");
            }
            return val;
        },
        times(val) {
            if ((val == "") || (val == undefined) || (val <= 0)) {
                val = "";
            } else {
                val = this.formatTimes(val);
            }
            return val;
        },
        formatTimes(value) {
            value = value * 60;
            let m = value % 60;
            let h = (value - m) / 60;

            m = 100 + m;
            m = (m + "").substr(1,2);
            let result = h + ":" + m;
            
            return result;
        },
        zanLevel(val) {
            let level = 0;

            if (val < 45) {
                level = 0;
            } else if (val < 80) {
                level = 1;
            } else if (val < 100) {
                level = 2;
            } else {
                level = 3;
            }

            return level;
        },
        zanTotalLevel(val) {
            let level = 0;

            if (val < 360) {
                level = 0;
            } else if (val < 600) {
                level = 1;
            } else if (val < 720) {
                level = 2;
            } else {
                level = 3;
            }

            return level;
        },
        empty_click() {

        }
    }
}
</script>

<style lang="less">
.managed-row {
    position: relative;

    td {
        padding: 6px 4px;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        white-space: nowrap;
        text-align: right;

        &:last-child {
            border-right: 1px solid black;
        }
    }

    .employNo, .name {
        text-align: left;
    }

    .zanlow {
        color: yellow;
    }
    .zanheight {
        color: orange;
    }
    .zanMax {
        color: orangered;
    }
}
</style>
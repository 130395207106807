<template>
    <div id="applyTravelPaymentEdit">
        <div class="backBtn" @click.stop="backPage()"><icon name="chevron-left" class="icon"></icon> 一覧</div>
        <div class="header">
            <div class="header1">
                <div class="date_area">申請日：<input type="date" v-model="date"/></div>
                <div class="total_area">合計：<span class="total">{{getAmount(total)}}</span><span class="unit">円</span></div>
            </div>
        </div>
        <div class="detail_list" ref="detail">
            <div class="list" ref="list">
                <div class="header2">
                    <div class="destination_area">訪問先：<input type="text" v-model="destination" /></div>
                    <div class="location_area">所在地：<input type="text" v-model="location" /></div>
                    <div class="matter_area">用　件：<input type="text" v-model="matter" /></div>
                    <div class="traffic_area">期　間：<input class="from" type="date" v-model="startDt" /> 〜 <input class="to" type="date" v-model="endDt" /></div>
                </div>
                <div class="header3">
                    <div class="etc_area">
                        <div class="title1">日当定額：</div>
                        <div>{{getAmount(diemsPrice, "¥")}} × <input type="number" v-model="diemsDays" @change.stop="change_nittou"/>&nbsp;日</div>
                        <div class="etc_money">{{getAmount(diems, "¥")}}</div>
                    </div>
                    <div class="etc_area">
                        <div>半日当定額：</div>
                        <div>{{getAmount(diemsHalfPrice, "¥")}} × <input type="number" v-model="diemsHalfDays" @change.stop="change_halfNittou"/>&nbsp;日</div>
                        <div class="etc_money">{{getAmount(diemsHalf, "¥")}}</div>
                    </div>
                    <div class="etc_area">
                        <div>宿泊料定額：</div>
                        <div>{{getAmount(stayPrice, "¥")}} × <input type="number" v-model="stayDays" @change.stop="change_stay"/>&nbsp;泊</div>
                        <div class="etc_money">{{getAmount(stay, "¥")}}</div>
                    </div>
                    <div class="etc_area">
                        <div>宿泊料実額：</div>
                        <div>¥&nbsp;<input class="stay_price" type="number" v-model="stayPrice2" @change.stop="change_stayPrice2"/> × <input type="number" v-model="stayDays2" @change.stop="change_stay2"/>&nbsp;泊</div>
                        <div class="etc_money">{{getAmount(stay2, "¥")}}</div>
                    </div>
                </div>
                <item class="detail" v-for="(dat, ix) in details" :key="ix"
                    :payDate="dat.payDate"
                    :facilities="dat.facilities"
                    :from="dat.from"
                    :to="dat.to"
                    :money="getMoney(dat.money)"
                    @paydate_change="paydate_change($event, ix)"
                    @facilities_change="facilities_change($event, ix)"
                    @from_change="from_change($event, ix)"
                    @to_change="to_change($event, ix)"
                    @change_money="change_money($event, ix)"
                    @menu_popup="menu_popup($event, ix)"
                ></item>
                <div class="button_area"><button @click.stop="save_travel">保存</button></div>
                <div v-if="!checkNewData" class="button_area"><button class="pdf_print" @click.stop="downloadBtn()">PDF出力</button></div>
                <div v-if="!checkNewData" class="button_area deleteBtn"><button @click.stop="delete_travel">削除</button></div>
                <ul v-show="menuFlag" ref="menu" class="menu_area">
                    <li><div @click.stop="onReversal()">反対方向を追加</div></li>
                    <li><div @click.stop="onDelete()">削除</div></li>
                    <li v-if="upbtnFlag"><div @click.stop="onUp()">１つ上に移動</div></li>
                    <li v-if="downbtnFlag"><div @click.stop="onDown()">１つ下に移動</div></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import item from '@/components/travelPaymentEdit.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    mixins: [utilityMixin],
    data() {
        return {
            headerTitle: "旅費精算",
            accountId: "",
            activeAccountId: "",
            menuFlag: false,
            userName: "",
            id: "",
            mode: "",
            near: false,
            date: "",
            destination: "",
            location: "",
            matter: "",
            startDt: "",
            endDt: "",
            diemsPrice: "",
            diemsDays: 0,
            diems: "",
            diemsHalfPrice: "",
            diemsHalfDays: 0,
            diemsHalf: "",
            stayPrice: "",
            stayDays: 0,
            stay: "",
            stayPrice2: "",
            stayDays2: 0,
            stay2: "",
            total: null,
            details: [],
            editedFlag: false,
            selectIdx: null,
            upbtnFlag: true,
            downbtnFlag: true
        };
    },
    components: {
        'item': item
    },
    computed: {
        checkNewData() {
            if (this.id != "" && this.id != undefined) {
                return false;
            } else {
                return true;
            }
        },
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;

            this.mode = this.$route.params.mode;
            this.id = this.$route.params.id;
            this.near = (this.$route.name === "nearTravel_edit") ? true : false;
            
            this.itemClear();
            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                this.details = [];
                let param = {
                    accountId: this.activeAccountId,
                    id: this.id,
                    near: this.near
                };
                let ress = await Vue.$cache.post('payment/travel/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.date = ress.data.data.date;
                        this.destination = ress.data.data.destination;
                        this.location = ress.data.data.location;
                        this.matter = ress.data.data.matter;
                        this.startDt = ress.data.data.startDate;
                        this.endDt = ress.data.data.endDate;
                        this.diemsPrice = ress.data.data.diemsPrice;
                        this.diemsDays = ress.data.data.diemsDays;
                        this.diems = ress.data.data.diems;
                        this.diemsHalfPrice = ress.data.data.diemsHalfPrice;
                        this.diemsHalfDays = ress.data.data.diemsHalfDays;
                        this.diemsHalf = ress.data.data.diemsHalf;
                        this.stayPrice = ress.data.data.stayMoney1;
                        this.stayDays = ress.data.data.stays1;
                        this.stay = ress.data.data.stay1;
                        this.stayPrice2 = ress.data.data.stayMoney2;
                        this.stayDays2 = ress.data.data.stays2;
                        this.stay2 = ress.data.data.stay2;
                        this.total = ress.data.data.total;
                        this.userName = ress.data.data.userName;
                        for (let p = 0; p < ress.data.data.detail.length; p++) {
                            let dat = ress.data.data.detail[p];
                            this.details.push(dat);
                        }
                        this.details.push({});
                        if (this.mode == "new") {
                            this.id = "";
                            let now = new Date();
                            this.date = now.toChar("yyyy-MM-dd");
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        save_travel() {
            if (this.valdate()) {
                this.setData();
            }
        },
        async setData() {
            try {
                let param = {
                    accountId: this.activeAccountId,
                    id: this.id,
                    date: this.date,
                    destination: this.destination,
                    location: this.location,
                    matter: this.matter,
                    startDate: this.startDt,
                    endDate: this.endDt,
                    diems: {
                        price: this.diemsPrice,
                        days: this.diemsDays,
                        money: this.diems
                    },
                    diemsHalf: {
                        price: this.diemsHalfPrice,
                        days: this.diemsHalfDays,
                        money: this.diemsHalf
                    },
                    stay1: {
                        price: this.stayPrice,
                        days: this.stayDays,
                        money: this.stay
                    },
                    stay2: {
                        price: this.stayPrice2,
                        days: this.stayDays2,
                        money: this.stay2
                    },
                    near: this.near,
                    detail: this.details.filter((x) => x.payDate != undefined)
                };
                console.log(param);
                let ress = await Vue.$cache.post('payment/travel/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.id = ress.data.data.id;
                        this.mode = "edit";
                        alert("更新されました");
                        setTimeout(() => {
                            if (this.near) {
                                this.jump_pg("/kintai/applypayment/nearTravel/edit/"+this.id);
                            } else {
                                this.jump_pg("/kintai/applypayment/travel/edit/"+this.id);
                            }
                        }, 0)
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        delete_travel() {
            if (confirm("指定のデータを削除しますか？") == false) {
                return;
            }
            this.deleteTravel();
        },
        async deleteTravel() {
            try {
                let param = {
                    accountId: this.activeAccountId,
                    id: this.id
                };
                let ress = await Vue.$cache.post('payment/travel/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.backPage();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        downloadBtn() {
            this.downloadPDF();
        },
        async downloadPDF() {
            try {
                let name = this.userName.replace(/\s+/g, "");
                let pdfFileName = this.date.replace(/-/g,"")+"_"+this.total+"_交通費精算_"+name+".pdf"
                let param = {
                    accountId: this.activeAccountId,
                    id: this.id,
                    applyMode: "travel"
                };

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'blob',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };

                let ress = await Vue.$cache.post('payment/travel/pdf/', param, this.$store, request);
                if (ress.status == 200) {
                    const url = URL.createObjectURL(new Blob([ress.data]));

                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', pdfFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch(error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        valdate() {
            //== 申請日の有無
            if (this.date == "" || this.date == undefined) {
                alert("申請日は省略出来ません");
                return false;
            }

            if (this.details == undefined || this.details.length == 0) {
                alert("内容が不明です");
                return false;
            }

            let notValidate = false;
            let cnt = 0;
            for (let p = 0; p < this.details.length; p++) {
                let data = this.details[p];
                if (data.payDate != undefined) {
                    if (data.payDate == "") {
                        alert("日付は省略出来ません");
                        notValidate = true;
                        break;
                    }
                    if (data.facilities == "" || data.facilities == undefined) {
                        alert("交通機関は省略出来ません");
                        notValidate = true;
                        break;
                    }
                    if (data.from == "" || data.from == undefined) {
                        alert("出発地は省略出来ません");
                        notValidate = true;
                        break;
                    }
                    if (data.to == "" || data.to == undefined) {
                        alert("到着地は省略出来ません");
                        notValidate = true;
                        break;
                    }
                    if (data.money == "" || data.money == undefined) {
                        alert("金額は省略出来ません");
                        notValidate = true;
                        break;
                    }
                } else {
                    ++cnt;
                }
            }

            /*
            if (cnt == this.details.length) {
                alert("内容が不明です");
                return false;
            }
            */

            if (notValidate) {
                return false;
            }

            return true;
        },
        change_nittou() {
            if (this.diemsPrice == "" || this.diemsPrice == undefined) {
                return;
            }
            this.diems = this.diemsPrice * this.diemsDays;
            this.calcTotal();
        },
        change_halfNittou() {
            if (this.diemsHalfPrice == "" || this.diemsHalfPrice == undefined) {
                return;
            }
            this.diemsHalf = this.diemsHalfPrice * this.diemsHalfDays;
            this.calcTotal();
        },
        change_stay() {
            if (this.stayPrice == "" || this.stayPrice == undefined) {
                return;
            }
            this.stay = this.stayPrice * this.stayDays;
            this.calcTotal();
        },
        change_stayPrice2() {
            if (this.stayPrice2 == "" || this.stayPrice2 == undefined) {
                return;
            }
            this.stay2 = this.stayPrice2 * this.stayDays2;
            this.calcTotal();
        },
        change_stay2() {
            if (this.stayPrice2 == "" || this.stayPrice2 == undefined) {
                return;
            }
            this.stay2 = this.stayPrice2 * this.stayDays2;
            this.calcTotal();
        },
        calcTotal() {
            let total = null;
            if (this.diems != "" && this.diems != undefined) {
                total += this.diems;
            }
            if (this.diemsHalf != "" && this.diemsHalf != undefined) {
                total += this.diemsHalf;
            }
            if (this.stay != "" && this.stay != undefined) {
                total += this.stay;
            }
            if (this.stay2 != "" && this.stay2 != undefined) {
                total += this.stay2;
            }
            this.details.forEach((x) => {
                if (x.money != "" && x.money != undefined) {
                    if (!Number.isNaN(parseInt(x.money))) {
                        total += (x.money - 0);
                    }
                }
            });
            this.total = total;
        },
        paydate_change(m, idx) {
            this.details[idx].payDate = m;
            this.cleanNode();
        },
        facilities_change(m, idx) {
            this.details[idx].facilities = m;
            this.cleanNode();
        },
        from_change(m, idx) {
            this.details[idx].from = m;
            this.cleanNode();
        },
        to_change(m, idx) {
            this.details[idx].to = m;
            this.cleanNode();
        },
        change_money(m, idx) {
            this.details[idx].money = m;
            this.cleanNode();
            this.calcTotal();
        },
        menu_popup(e, idx) {
            let top = e.layerY;
            
            if (this.details[idx].payDate == "" || this.details[idx].payDate == undefined) {
                return;
            }

            this.$refs.menu.style.top = top+"px";
            this.selectIdx = idx;

            if (this.selectIdx === 0) {
                this.upbtnFlag = false;
            } else {
                this.upbtnFlag = true;
            }

            if (this.selectIdx === (this.details.length - 2)) {
                this.downbtnFlag = false;
            } else {
                this.downbtnFlag = true;
            }

            this.menuFlag = true;
        },
        onReversal() {
            let list = [...this.details];
            let node = JSON.parse(JSON.stringify(list[this.selectIdx]));
            let from = node.from;
            node.from = node.to;
            node.to = from;
            this.details.splice(this.details.length - 1, 0, node);

            this.menuFlag = false;
            this.cleanNode();
            this.calcTotal();
        },
        onDelete() {
            this.details.splice(this.selectIdx, 1);
            this.menuFlag = false;
            this.cleanNode();
            this.calcTotal();
        },
        onUp() {
            let node1 = this.details[this.selectIdx - 1];
            let node2 = this.details[this.selectIdx];

            this.details.splice(this.selectIdx, 1, node1);
            this.details.splice(this.selectIdx - 1, 1, node2);

            this.menuFlag = false;
            this.cleanNode();
            this.calcTotal();
        },
        onDown() {
            let node1 = this.details[this.selectIdx];
            let node2 = this.details[this.selectIdx + 1];

            this.details.splice(this.selectIdx, 1, node2);
            this.details.splice(this.selectIdx + 1, 1, node1);

            this.menuFlag = false;
            this.cleanNode();
            this.calcTotal();
        },
        itemClear() {
            let now = new Date();
            this.date = now.toChar("yyyy-MM-dd");
            this.details = [];
        },
        cleanNode() {
            let details = this.details.filter((x) => {
                return !this.checkEmptyNode(x);
            });
            this.details = details;
            this.details.push({});
        },
        checkEmptyNode(node) {
            if (node.payDate != "" && node.payDate != undefined) {
                return false;
            }
            if (node.facilities != "" && node.facilities != undefined) {
                return false;
            }
            if (node.from != "" && node.from != undefined) {
                return false;
            }
            if (node.to != "" && node.to != undefined) {
                return false;
            }
            if (node.money != "" && node.money != undefined) {
                return false;
            }
            return true;
        },
        getMoney(val) {
            if (val == "" || val == undefined) {
                return "";
            } else {
                return val.toString(10);
            }
        },
        getAmount(val) {
            return Vue.$utils.convertCurrency(val);
        },
        backPage() {
            if (this.editedFlag) {
                if (confirm("保存していないデータがあります。\n\n破棄して戻りますか？") == false) {
                    return;
                }
            }
            if (this.near) {
                this.jump_pg("/kintai/applypayment/nearTravel");
            } else {
                this.jump_pg("/kintai/applypayment/travel");
            }
        },
        jump_pg(key, param) {
            this.paging += 1;
            if (param != undefined) {
                this.$store.commit("operator_routeParam", param);
            }
            this.$router.push(key, () => {});
        },
        base_click() {
            this.menuFlag = false;
        }
    }
}
</script>

<style lang="less" scoped>
#applyTravelPaymentEdit {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: min-content auto;

    .header {
        padding-bottom: 6px;
        border-bottom: 1px solid var(--grayoutBackColor);
        
        .header1 {
            display: grid;
            grid-template-columns: 180px auto;

            .date_area {
                display: flex;
                justify-content: left;
                align-items: flex-end;

                input {
                    width: 114px;
                    height: 28px;
                }
            }

            .total_area {
                text-align: right;

                .total {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
            }
        }
    }

    .detail_list {
        overflow: auto;

        .list {
            position: relative;
            min-height: 100%;

            .header2 {
                .destination_area, .location_area, .matter_area {
                    padding-top: 6px;

                    input {
                        width: calc(100% - 64px);
                        height: 28px;
                    }
                }

                .traffic_area {
                    padding-top: 6px;
                    padding-bottom: 6px;

                    input {
                        width: 114px;
                        height: 28px;
                    }
                }
            }

            .header3 {
                border-top: 1px solid var(--grayoutBackColor);
                border-bottom: 1px solid var(--grayoutBackColor);
                padding-top: 6px;
                
                .etc_area {
                    display: grid;
                    grid-template-columns: auto auto 80px;
                    padding-bottom: 6px;

                    input {
                        width: 40px;
                        height: 28px;
                        text-align: right;
                    }

                    input.stay_price {
                        width: 60px;
                    }

                    .title1 {
                        letter-spacing: 4.1px;
                        text-indent: -1px;
                    }

                    .etc_money {
                        text-align: right;
                    }
                }
            }

            .detail {
                margin-top: 8px;
            }

            .button_area {
                margin-top: 22px;
                width: 100%;
                text-align: center;

                button {
                    width: calc(100% - 42px);
                    letter-spacing: 20px;
                    text-indent: 20px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                }

                .pdf_print {
                    letter-spacing: 4px;
                    text-indent: 4px;
                }
            }

            .deleteBtn {
                button {
                    background-color: red;
                    border: 2px solid red;
                    color: white;
                }
            }

            .menu_area {
                position: absolute;
                right: 24px;
                background-color: var(--backColor);
                color: var(--foreColor);
                min-width: 100px;
                margin: 0;
                padding: 0;
                list-style: none;
                box-shadow: 0 0 8px 2px rgba(0,0,0,0.2);
                border: 1px solid var(--grayoutBackColor);
                border-radius: 4px;

                li {
                    display: inline;
                    padding: 0;
                    margin: 0;

                    div {
                        padding: 6px 12px 0;
                    }

                    &:last-child {
                        div {
                            padding: 6px 12px;
                        }
                    }
                }
            }
        }
    }

    .downloadBtn {
        position: absolute;
        top: 58px;
        right: 12px;

        button {
            padding: 0 6px;
        }
    }

    .backBtn {
        position: absolute;
        top: 58px;
        left: 12px;
        cursor: pointer;
        color: #42a4ff;

        .icon {
            vertical-align: text-top;
        }
    }

    .icon {
        fill: #42a4ff;
    }
}
</style>
<template>
    <tr class="payslip-row">
        <td class="pdf" @click.stop="check_pdf"><input type="checkbox" v-model="item.checked"/></td>
        <td class="type">{{cvtType(item.meisaiType)}}</td>
        <td class="yearMonth">{{cvtDate(item.paymentDate)}}</td>
        <td class="print" @click.stop="print"><icon name="file-pdf" class="icon"></icon></td>
    </tr>
</template>

<script>
export default {
    props: {
        item: null
    },
    watch: {
        'item.checked'(nv, ov) {
            this.$emit('changeChecked', nv);
        }
    },
    methods: {
        cvtType(val) {
            if (val == "kyuyo") {
                val = "給与";
            } else {
                val = "賞与";
            }
            return val;
        },
        cvtDate(val) {
            let vals = val.split("-");
            val = vals[0]+"年"+vals[1]+"月支給分";
            return val;
        },
        print() {
            this.$emit('print', this.item.meisaiType, this.item.closingDate, this.item.paymentDate);
        },
        check_pdf() {

        }
    }
}
</script>

<style lang="less">
.payslip-row {
    position: relative;

    td {
        padding: 12px 4px;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        white-space: nowrap;

        &:last-child {
            border-right: 1px solid black;
        }
    }

    .yearMonth {
        padding-left: 12px;
    }

    .pdf, .type, .print {
        text-align: center;
    }

    .print {
        cursor: pointer;

        .icon {
            width: 14px;
            fill: var(--foreColor);
        }
    }
}
</style>
<template>
    <div class="card" :class="{'selected':select, 'visible':!visible, 'editer':editFlag, 'newCard':newFlag}" :style="`z-index:${zindex}; top:${top}px`" @click.stop="card_click($event)">
        <div class="header_area">
            <table class="header">
                <tr>
                    <td class="title">{{dtitle}}<span class="apply_status" :class="{'warnning': warnStatus}">{{vstatus}}</span></td>
                    <td class="detail" v-if="!newFlag">
                        <div class="detail_title">取得日時</div>
                        <div>{{vstart2}}</div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="detail_area">
            <table class="detail">
                <tr v-if="newFlag">
                    <td class="title">休暇種類</td>
                    <td>
                        <select :value="dleaveType" @change="change_leaveType($event)">
                            <option v-for="(item, ix) in dleaveTypeList" :key="ix" :value="item.code">{{item.name}}</option>
                        </select>
                    </td>
                </tr>
                <tr v-if="(dleaveType == '2')">
                    <td class="title">特休種別</td>
                    <td v-if="!editFlag">{{getSpecialLeaveName}}</td>
                    <td v-if="editFlag">
                        <select :value="dspecialleave" @input="change_specialleave($event)">
                            <option v-for="(item, ix) in dspecialleaveList" :key="ix" :value="item.code">{{item.name}}</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td class="title">有休残日数</td>
                    <td>{{zanYukyu}}</td>
                </tr>
                <tr>
                    <td class="title">時間指定</td>
                    <td>
                        <div class="toggle">
                            <label class="check_bar" :class="{'checked': dtimeon}">
                                <input type="checkbox" v-bind:checked="dtimeon" :disabled="!editFlag" @change="change_timeon($event)">
                            </label>
                            <div id="swImg" :class="{'checked': dtimeon}"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="title">開始</td>
                    <td v-if="!editFlag">{{vstart}}</td>
                    <td v-if="editFlag"><ui-datetime-org v-if="dtimeon" :source="dstarttime" :className="'ui-datetimelocal-class'" @change="change_start"/><ui-date v-if="!dtimeon" :source="dstart" :className="'ui-date-class'" @input="change_start2"/></td>
                </tr>
                <tr>
                    <td class="title">{{endTitle}}</td>
                    <td v-if="!editFlag">{{vend}}</td>
                    <td v-if="editFlag && !dtimeon"><ui-date :source="dend" :className="'ui-date-class'" @input="change_end($event)"/></td>
                    <td v-if="editFlag && dtimeon"><input class="leave_times" type="number" :value="dendtime" :step="timeStep" :min="timeStep" :max="timeMax" @blur="change_end($event)"/>&nbsp;時間</td>
                </tr>
                <tr>
                    <td class="labe-lonly" colspan="2">備考・理由</td>
                </tr>
                <tr>
                    <td v-if="!editFlag" class="comment" colspan="2">
                        <div class="comment_area">{{comment}}</div>
                    </td>
                    <td v-if="editFlag" class="comment" colspan="2">
                        <textarea :value="dcomment" @change="change_comment($event)"></textarea>
                    </td>
                </tr>
                <tr>
                    <td v-if="applydate!=''" class="information" colspan="2">申請日：{{vapplydate}}</td>
                </tr>
                <tr v-if="approval || status == '3'">
                    <td class="labe-lonly" colspan="2">差戻し理由</td>
                </tr>
                <tr v-if="approval || status == '3'">
                    <td v-if="!approval" class="comment" colspan="2">
                        <div class="comment_area">{{remandComment}}</div>
                    </td>
                    <td v-else class="comment" colspan="2">
                        <textarea :value="dremandComment" @change="change_remandComment($event)"></textarea>
                    </td>
                </tr>
            </table>
        </div>
        <div class="footer_area" v-if="approval">
            <div class="approvalBtn largeBtn" v-if="status != '2'" @click.stop="card_approval($event)">承認する</div>
            <div class="remandBtn largeBtn" v-if="status != '2'" @click.stop="card_remand($event)">差し戻す</div>
            <div class="deleteBtn largeBtn" v-if="status == '2'" @click.stop="card_approvalCancel($event)">承認の取り消し</div>
        </div>
        <div v-else class="footer_area">
            <div class="editBtn largeBtn" v-if="!newFlag && !editFlag && status != '2'" @click.stop="card_edit($event)">編集する</div>
            <div class="updateBtn largeBtn" v-if="editFlag" @click.stop="card_update($event)">登　　録</div>
            <div class="deleteBtn largeBtn" v-if="!newFlag && status != '2'" @click.stop="card_delete($event)">削除する</div>
            <div class="deleteBtn largeBtn" v-if="!newFlag && status == '2'" @click.stop="card_cancel($event)">取り下げる</div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import uiDate  from '@/components/ui/date.vue';
import uiDatetimeOrg  from '@/components/ui/datetime-org.vue';
export default {
    components: {
        'ui-date': uiDate,
        'ui-datetime-org': uiDatetimeOrg
    },
    props: {
        accountId: {type: String, default: ""},
        leaveId: {type: String, default: ""},
        timestamp: {type: String, default: ""},
        leaveType: {type: String, default: "1"},
        specialleave: {type: String, default: ""},
        start: {type: String, default: ""},
        end: {type: String, default: ""},
        comment: {type: String, default: ""},
        remandComment: {type: String, default: ""},
        applydate: {type: String, default: ""},
        status: {type: String, default: ""},
        leaveTypeList: null,
        specialleaveList: null,
        zanYukyu: {type: String, default: ""},
        select: {type: Boolean, default: false},
        visible: {type: Boolean, default: true},
        approval: {type: Boolean, default: false},
        top: {type: Number, default: 1},
        zindex: {type: Number, default: 1},
        newFlag: {type: Boolean, default: false}
    },
    computed: {
        getSpecialLeaveName() {
            let code = this.dspecialleave;
            if (code == "" || code == undefined) { code = "11"; }
            let datas = this.dspecialleaveList.filter((item) => {
                return item.code == code;
            });
            if (datas.length == 0) {
                return "";
            }
            return datas[0].name;
        },
        vstart() {
            let result = "";
            if (this.dstart != "" && this.dstart != undefined) {
                result = this.dstart.replace(/-/g, "/");
            } else if (this.dstarttime != "" && this.dstarttime != undefined) {
                result = this.dstarttime.replace(/-/g, "/");
            }
            return result;
        },
        vstart2() {
            let result = "";
            let dstart = this.dstart;
            if (this.dstart == "" || this.dstart == undefined) {
                dstart = this.dstarttime;
            }
            if (dstart != "" && dstart != undefined) {
                let wdat = dstart.replace(/-/g, "/");
                let date = new Date(dstart.replace(" ", "T"));
                let now = new Date();
                let y = date.getFullYear();
                if (y == now.getFullYear()) {
                    y = "";
                } else {
                    y = y+"/";
                }
                let m = date.getMonth();
                m = (m + 1) + "/";
                let d = date.getDate() - 0 + 100;
                d = String(d).substr(1, 2);
                if (wdat.split(" ").length > 1) {
                    let tm = date.toChar("H:mm");
                    result = y + m + d + " " + tm;
                } else {
                    result = y + m + d;
                }
            }
            return result;
        },
        vend() {
            let result = "";
            if (this.dendtime != "" && this.dendtime != undefined) {
                result = this.dendtime + " 時間";
            } else if (this.dend != "" && this.dend != undefined) {
                result = this.dend.replace(/-/g, "/");
            }
            return result;
        },
        vapplydate() {
            let result = "";
            if (this.applydate != "" && this.applydate != undefined) {
                let applydate = this.applydate;
                applydate = applydate.replace(" ", "T");
                let ad = new Date(applydate);
                result = ad.toChar("yyyy/MM/dd HH:mm");
            }
            return result;
        },
        vstatus() {
            let result = "";
            if (this.status == "2") {
                result = "（承認）";
            } else if (this.status == "3") {
                result = "（差戻し）";
            } else if (this.status == "1") {
                result = "（承認待ち）";
            } else if (this.status == "4") {
                result = "（取下げ承認待ち）";
            }
            return result;
        },
        ctitle() {
            let result = "";
            if (this.newFlag) {
                result = "新規申請";
            } else if (this.dleaveType == 2) {
                result = this.getSpecialLeaveName;
            } else {
                result = this.getYukyuName();
            }
            return result;
        }
    },
    data() {
        return {
            editFlag: false,
            dleaveTypeList: [],
            dspecialleaveList: [],
            endTitle: "終了",
            dtitle: "",
            dstart: "",
            dstarttime: "",
            dend: "",
            dendtime: "",
            dleaveType: "1",
            dspecialleave: "",
            dcomment: "",
            dremandComment: "",
            dtimeon: false,
            specialleaveName: "",
            warnStatus: false,
            timeStep: 0.5,
            timeMax: 7.5
        };
    },
    mounted() {
        if (this.start.split(" ").length > 1) {
            this.dstarttime = this.start;
        } else {
            this.dstart = this.start;
        }
        if (this.end.split("-").length < 2) {
            this.dendtime = this.end;
        } else {
            this.dend = this.end;
        }
        this.dleaveType = this.leaveType;
        this.dspecialleave = this.specialleave;
        console.log(this.dspecialleave);
        if (this.dspecialleave == "" || this.dspecialleave == undefined) {
            this.dspecialleave = "11";
        }
        this.dcomment = this.comment;
        this.dremandComment = this.remandComment;
        if (this.start != "" && this.start != undefined) {
            this.dtimeon = (this.start.split(" ").length > 1) ? true : false;
        } else if (this.end != "" && this.end != undefined) {
            this.dtimeon = (this.end.split("-").length < 2) ? true : false;
        }
        if (this.dtimeon) {
            this.endTitle = "時間";
        }
        this.dleaveTypeList = this.leaveTypeList;
        this.dspecialleaveList = this.specialleaveList;
        this.editFlag = this.newFlag;
        this.dtitle = this.ctitle;
        if (this.status == "3" || this.status == "4") {
            this.warnStatus = true;
        } else {
            this.warnStatus = false;
        }
    },
    methods: {
        card_click(e) {
            this.$emit('card_click', e);
        },
        card_edit(e) {
            this.editFlag = true;
        },
        card_approval(e) {
            this.setApproval("1");
        },
        card_remand(e) {
            this.setApproval("2");
        },
        card_approvalCancel(e) {
            this.setApproval("3");
        },
        card_cancel(e) {
            const mess = "申請を取り下げますか？\n\nこの操作は元に戻せません。";
            if (!confirm(mess)) {
                return false;
            }
            this.delete();
        },
        card_delete(e) {
            const mess = "削除しますか？\n\nこの操作は元に戻せません。";
            if (!confirm(mess)) {
                return false;
            }
            this.delete();
        },
        card_update(e) {
            let start = this.dstart;
            let end = this.dend;
            if (this.dtimeon) {
                start = this.dstarttime;
                end = this.dendtime;
            }
            let editData = {
                start: start,
                end: end,
                leaveType: this.dleaveType,
                specialleave: this.dspecialleave,
                comment: this.dcomment,
            };
            let err = this.checkedData(editData);
            if (err != "") {
                alert(err);
                return false;
            }

            this.save(editData);
        },
        change_start2(e) {
            this.change_start(e.target.value);
        },
        change_start(value) {
            let start = this.alignDatetime(value.replace("T", " "), 1800);
            let end = this.dend;
            if (this.dtimeon) {
                end = this.dendtime;
            }
            if (start != "" && start != undefined) {
                if (this.dtimeon) {
                    let sday = start.split("T")[0];
                    let sd = new Date(start);
                    let sd1 = new Date(sday + "T09:00");
                    let sd2 = new Date(sday + "T17:30");
                    if (sd.getTime() < sd1.getTime()) {
                        start = sd1.toChar("yyyy-MM-ddTHH:mm");
                    } else if (sd.getTime() > sd2.getTime()) {
                        start = sd2.toChar("yyyy-MM-ddTHH:mm");
                    }

                    sd = new Date(start);
                    let wsd = new Date(sday + "T18:00");
                    let c = wsd.getTime() - sd.getTime();
                    let max = c / 60 / 60 / 1000;
                    if (max > 7.5) {
                        this.timeMax = 7.5;
                    } else if (max <= 0.5) {
                        this.timeMax = 0.5;
                        end = "0.5";
                    } else {
                        this.timeMax = max;
                    }

                    if (this.dendtime != undefined && this.dendtime != "") {
                        if (this.dendtime > max) {
                            end = max;
                        }
                    }
                } else {
                    if (this.dend == undefined || this.dend == "") {
                        end = start;
                    } else {
                        let ed1 = new Date(this.dend);
                        let sd1 = new Date(start);
                        let comp = ed1.getTime() - sd1.getTime();
                        if (comp < 0) {
                            if (this.dstart == "" || this.dstart == undefined) {
                                start = this.dend;
                            } else {
                                let sd2 = new Date(this.dstart);
                                let comp1 = ed1.getTime() - sd2.getTime();
                                let dd2 = new Date();
                                dd2.setTime(sd1.getTime() + comp1);
                                end = dd2.toChar("yyyy-MM-dd");
                            }
                        } else {
                            if (this.dstart == "" || this.dstart == undefined) {
                                start = this.dend;
                            } else {
                                let sd2 = new Date(this.dstart);
                                let comp1 = ed1.getTime() - sd2.getTime();
                                let dd3 = new Date();
                                dd3.setTime(sd1.getTime() + comp1);
                                end = dd3.toChar("yyyy-MM-dd");
                            }
                        }
                    }
                }
            } else {
                end = "";
            }

            if (this.dtimeon) {
                this.dstarttime = start.replace("T", " ");
                this.dendtime = end;
            } else {
                this.dstart = start;
                this.dend = end;
            }
            
            let timeon = this.dtimeon;
            this.dtimeon = !this.dtimeon;
            this.$nextTick(() => {
                this.dtimeon = timeon;
                this.dtitle = this.ctitle;
            });
        },
        change_end(e) {
            let end = e.target.value;
            if (end != "" && end != undefined) {
                if (this.dtimeon) {
                    if (end < 0.5) { end = 0.5; }
                    if (this.dstarttime != "" && this.dstarttime != undefined) {
                        let sday = this.dstarttime.split(" ")[0];
                        let sd12 = new Date(this.dstarttime.replace(" ", "T"));
                        let sd13 = new Date();
                        let wsd = new Date(sday + "T18:00");
                        let wend = end * 60 * 60 * 1000;
                        sd13.setTime(sd12.getTime() + wend);
                        let comp = wsd.getTime() - sd13.getTime();
                        comp = comp / 60 / 60 / 1000;
                        if (comp < 0) {
                            let comp2 = wsd.getTime() - sd12.getTime();
                            end = comp2 / 60 / 60 / 1000;
                        }
                    } else {
                        if (end < 0.5) {
                            end = "0.5";
                        } else if (end > 7.5) {
                            end = 7.5;
                        }
                    }
                } else {
                    let sd1 = new Date(this.dstart);
                    let ed1 = new Date(end);
                    let comp = ed1.getTime() - sd1.getTime();
                    
                    if (comp < 0) {
                        if (this.dend != undefined && this.dend != "") {
                            let ed2 = new Date(this.dend);
                            end = ed2.toChar("yyyy-MM-dd");
                        } else {
                            end = "";
                        }
                    }
                }
            }

            if (this.dtimeon) {
                this.dendtime = end;
            } else {
                this.dend = end;
            }
            let timeon = this.dtimeon;
            this.dtimeon = !this.dtimeon;
            this.$nextTick(() => {
                this.dtimeon = timeon;
                this.dtitle = this.ctitle;
            });
        },
        change_timeon(e) {
            this.dtimeon = e.target.checked;
            if (this.dtimeon) {
                if (this.dstart != "" && this.dstart != undefined) {
                    this.dstarttime = this.dstart + " 09:00";
                    this.dstart = "";
                    this.dend = "";
                }
                this.dendtime = "";
                this.endTitle = "時間";
            } else {
                if (this.dstarttime != "" && this.dstarttime != undefined) {
                    this.dstart = this.dstarttime.split(" ")[0];
                    this.dend = this.dstart;
                }
                this.endTitle = "終了";
            }
        },
        change_leaveType(e) {
            this.dleaveType = e.target.value;
            this.dtitle = this.ctitle;
        },
        change_specialleave(e) {
            this.dspecialleave = e.target.value;
            this.dtitle = this.ctitle;
        },
        change_comment(e) {
            this.dcomment = e.target.value;
        },
        change_remandComment(e) {
            this.dremandComment = e.target.value;
        },
        checkedData(data) {
            let result = "";
            let loop = false;

            do {
                if (data.leaveType == "") {
                    result = "休暇種類は必須です。";
                    break;
                }

                if (data.leaveType == "2" && data.specialleave == "") {
                    result = "特休種別は必須です。";
                    break;
                }

                if (data.start == "") {
                    result = "開始日時は必須です。";
                    break;
                }

                let startdm = this.alignDatetime(data.start, 1800);
                startdm = startdm.replace("T", " ");
                if (data.start != startdm) {
                    result = "申請は30分単位です。";
                    break;
                }

                if (!this.dtimeon) {
                    if (data.end != "" && data.end != undefined) {
                        let sd1 = new Date(data.start);
                        let ed1 = new Date(data.end);
                        let comp = ed1.getTime() - sd1.getTime();
                        if (comp < 0) {
                            result = "終了日時が不正です。";
                            break;
                        }
                    }
                } else {
                    if (data.end == "" || data.end == undefined) {
                        result = "終了は必須です。";
                        break;
                    } else {
                        let sday = data.start.split(" ")[0];
                        let ed1 = new Date(sday + "T18:00");
                        let ed2 = new Date(data.start.replace(" ", "T"));
                        let wt = data.end * 60 * 60 * 1000;
                        ed2.setTime(ed2.getTime() + wt);
                        let comp = ed1.getTime() - ed2.getTime();
                        if (comp < 0) {
                            result = "終了時間が不正です。";
                            break;
                        }
                    }
                }
            } while(loop);

            return result;
        },
        async save(editData) {
            try {
                let startDate = editData.start.split(" ")[0];
                let endDate = editData.end;
                let startTime = "";
                let leaveTimes = "";
                let timeType = "1";
                if (editData.start.split(" ").length > 1) {
                    startTime = editData.start.split(" ")[1];
                    leaveTimes = endDate;
                    timeType = "3";
                    endDate = "";
                }

                let param = {
                    accountId: this.accountId,
                    leaveId: this.leaveId,
                    timestamp: this.timestamp,
                    host: window.location.origin,
                    data: {
                        startDate: startDate,
                        endDate: endDate,
                        startTime: startTime,
                        leaveTimes: leaveTimes,
                        leaveType: editData.leaveType,
                        specialleaveCode: editData.specialleave,
                        timeType: timeType,
                        comment: editData.comment
                    }
                };

                const ress = await Vue.$cache.post("leave/request/", param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        let message = ress.data.data.messageNo;
                        if (message !="" && message != undefined) {
                            alert(message);
                            return;
                        }
                        this.$emit('card_update', editData);
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        async delete() {
            try {
                let param = {
                    accountId: this.accountId,
                    leaveId: this.leaveId,
                    comment: "",
                    timestamp: this.timestamp
                };

                const ress = await Vue.$cache.post("leave/delete/", param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        let message = ress.data.data.messageNo;
                        if (message !="" && message != undefined) {
                            alert(message);
                            return;
                        }
                        this.$emit('card_delete');
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        async setApproval(status) {
            try {
                let param = {
                    leaveId: this.leaveId,
                    syoninMode: status,
                    accountId: this.accountId,
                    timestamp: this.timestamp,
                    data: {
                        remandComment: this.dremandComment
                    }
                };

                let ress = await Vue.$cache.post('leave/syonin/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        let message = ress.data.data.messageNo;
                        if (message !="" && message != undefined) {
                            alert(message);
                            return;
                        }
                        this.$emit('card_update', null);
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        alignDatetime(value, step) {
            let result = value;
            if (value.split(" ").length > 1) {
                let date = new Date(value.split(" ")[0]+"T"+value.split(" ")[1]);
                let time = date.getTime() / 1000;
                let am = time % step;
                let dt = new Date();
                if (am > 0) {
                    dt.setTime((time - am + step) * 1000);
                } else {
                    dt.setTime(time * 1000);
                }
                
                result = dt.toChar("yyyy-MM-ddTHH:mm");
            }
            return result;
        },
        getYukyuName() {
            let result = "有給休暇";
            if (this.dtimeon) {
                if (this.dstarttime != "" && this.dstarttime != undefined && this.dendtime != "" && this.dendtime != undefined) {
                    let sday = this.dstarttime.split(" ")[0];
                    let ed1 = new Date(sday + "T18:00");
                    let ed2 = new Date(this.dstarttime.replace(" ", "T"));
                    let wt = this.dendtime * 60 * 60 * 1000;
                    ed2.setTime(ed2.getTime() + wt);
                    let sec = (ed1.getTime() - ed2.getTime()) / 1000;
                    let h = sec / 60 / 60;
                    if (h > 6) { h -= 1; }
                    if (h < 8) {
                        result = "半日休暇";
                    }
                }
            }
            return result;
        }
    }
}
</script>

<style lang="less">
.card {
    position:absolute;
    background-color: #fff;
    color: #000;
    left: 20px;
    right: 20px;
    margin: auto;
    height: 490px;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    padding: 6px 10px;
    display: grid;
    grid-template-rows: 36px auto 80px;

    .header_area {
        .header {
            width: 100%;

            .title {
                font-weight: bold;

                .apply_status {
                    font-size: 0.82rem;
                    font-weight: normal;
                }

                .apply_status.warnning {
                    color: #d33e3e;
                }
            }

            .detail {
                font-size: 0.8rem;
                text-align: right;

                .detail_title {
                    font-size: 0.6rem;
                }
            }
        }
    }

    .detail_area {
        font-size: 0.9rem;
        overflow: auto;

        .detail {
            font-size: 0.9rem;
            width: 100%;
            border-collapse: collapse;
            border: 0;

            td {
                padding: 6px 0;
            }

            td.title {
                width: 100px;
            }

            td.labe-lonly {
                padding: 6px 0 0;
            }

            td.comment {
                padding: 2px 0 6px;

                textarea {
                    width: 100%;
                    padding: 2px 6px;
                }
            }

            td.information {
                font-size: 0.8rem;
            }

            .toggle {
                position: relative;
                width: 48px;
                background-color: #fff;

                input[type="checkbox"] {
                    display: none;
                }

                label {
                    display: block;
                    box-sizing: border-box;
                    height: 24px;
                    border: 1px solid #999;
                    border-radius  : 12px;
                    cursor: pointer;
                }

                label.checked {
                    border-color: #55a5ff;
                }
                
                #swImg {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background: #999;
                    top: 2px;
                    left: 2px;
                    border-radius: 10px;
                    transition: .2s;
                }

                #swImg.checked {
                    transform: translateX(24px);
                    background: #55a5ff;
                }
            }

            .comment_area {
                border: 1px solid #5c5c5c;
                border-radius: 6px;
                padding: 6px;
                min-height: 36px;
            }

            .leave_times {
                width: 80px;
            }
        }
    }
    
    .footer_area {
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        align-items: center;

        .editBtn, .updateBtn, .deleteBtn {
            font-size: 0.9rem;
            font-weight: bold;
        }

        .deleteBtn{
            background-color: #d33e3e;
            color: #fff;
        }
    }

    input[type="number"], input[type="date"], input[type="datetime-local"], select, option, textarea {
        background-color: #fff;
        color: #000;
    }

    input[type="number"], input[type="date"], input[type="datetime-local"], select, option {
        height: 28px;
    }
}

.card.selected {
    top: 40px;
}

.card.visible {
    display: none;
}

.card.editer.newCard {
    grid-template-rows: 36px auto 38px;
}

.largeBtn {
    width: 80%;
    padding: 4px 0;
    text-align: center;
    cursor: pointer;
    background-color: #dcdcdc;
    border-radius: 4px;
}
</style>
<template>
    <div id="payslipPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <button class="pdf_btn" @click.stop="pdfDownload()">まとめて出力</button>
                <div class="yearArea"><span class="btnMoveYearPre" @click.stop="mvYear(-1)">＜</span><span class="year">{{activeYear}}年度</span><span class="btnMoveYearNext" @click.stop="mvYear(1)">＞</span></div>
            </div>
            <!-- 表 -->
            <table class="list">
                <tr class="list-header">
                    <td class="pdf"></td>
                    <td class="type">給賞与</td>
                    <td class="yearMonth">支給月</td>
                    <td class="print">PDF</td>
                </tr>
                <payData v-for="(data) in items" :key="data.meisaiType+','+data.closingDate" :item="data" @changeChecked="changeChecked" @print="print"></payData>
            </table>
            <!-- フッター -->
            <div class="footer">

            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import payData  from '@/components/payslipData.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "payslipPg",
    mixins: [utilityMixin],
    components: {
        'payData': payData
    },
    data() {
        return {
            headerTitle: "給与明細",
            accountId: "",
            activeYear: 0,
            items: [],
            isProcessing: false,
            searchVal: "",
            downloadFileName: "",
            ids: []
        }
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }
        
        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;

            const now = new Date();
            if (this.activeYear == 0) {
                this.activeYear = now.getFullYear();
            }

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                this.items = [];
                
                let param = {
					accountId: this.accountId,
                    year: this.activeYear
                };
                let ress = await Vue.$cache.post('payslip/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        ress.data.data.list.forEach(function(item){
                            item.checked = false;
                        });
                        this.items = ress.data.data.list;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        mvYear(m) {
            if (m < 1) {
                --this.activeYear;
            } else {
                ++this.activeYear;
            }

            this.getData();
        },
        changeChecked(val) {
            let oncnt = 0;
            this.items.forEach(function(item){
                if (item.checked) {
                    oncnt += 1;
                }
            });
            if (oncnt == this.items.length) {
                this.allChecked = true;
            } else if (oncnt == 0) {
                this.allChecked = false;
            }
        },
        print(meisaiType, closingDate, paymentDate) {
            let val = {'meisaiType':meisaiType, 'closingDate':closingDate, 'paymentDate':paymentDate};
            this.pdfDownload(val)
        },
        async pdfDownload(arg) {
            try {
                this.ids = [];
                let _self = this;
                if (arg == undefined) {
                    this.items.forEach(function(item){
                        if (item.checked == true) {
                            let val = {'meisaiType':item.meisaiType, 'closingDate':item.closingDate, 'paymentDate':item.paymentDate};
                            _self.ids.push(val);
                        }
                    });
                } else {
                    _self.ids.push(arg);
                }
                if (this.ids.length < 1) {
                    return;
                }
                let msnm = "給与";
                let tgtm = this.activeMonth+"月";
                if (this.meisaiType != "kyuyo") {
                    msnm = "賞与";
                    tgtm = this.activeSeason;
                }
                if (this.ids.length == 1) {
                    let msnm = "給与";
                    if (this.ids[0].meisaiType != "kyuyo") { msnm = "賞与"}
                    let yms = this.ids[0].paymentDate.split("-");
                    let yearMonth = yms[0]+"年"+yms[1]+"月支給分";
                    this.downloadFileName = msnm+"明細_"+yearMonth+".pdf"
                } else {
                    this.downloadFileName = "給与・賞与明細.pdf"
                }

                let param = {
                    dataIds: this.ids,
                    userId: this.accountId
                };

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'blob',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };
                
                let ress = await Vue.$cache.post('payslip/pdfs/', param, this.$store, request);
                if (ress.status == 200) {
                    const url = URL.createObjectURL(new Blob([ress.data]));

                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', this.downloadFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        close_win() {
            this.csvWindow = false;
            this.$store.dispatch("operator_backPanel", "");
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#payslipPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .header {
            height: 42px;

            .pdf_btn {
                padding: 0 6px;
            }

            .yearArea {
                width: 100%;
                font-size: 1.3rem;
                font-weight: bold;
                display: inline;
                margin-left: 32px;

                .btnMoveYearPre {
                    margin-right: 8px;
                    cursor: pointer;
                }

                .btnMoveYearNext {
                    margin-left: 8px;
                    cursor: pointer;
                }
            }
        }

        .list {
            width: 100%;
            border-spacing: 0;

            .list-header {
                .pdf {
                    width: 36px;
                }
                .type {
                    width: 80px;
                }
                .print {
                    width: 48px;
                }
            }

            .list-header {
                background-color: var(--gridFixedBackColor);
                font-weight: bold;
                color: white;

                td {
                    border-bottom: 1px solid white;
                    border-bottom-color: var(--gridFixedBorderColor);
                    border-left: 1px solid white;
                    border-left-color: var(--gridFixedBorderColor);
                    padding: 6px 4px;
                    text-align: center;
                    white-space: nowrap;

                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:550px) {
    #payslipPg {
        .main_area {
            padding: 78px 0 0;
            overflow: auto;
        }
    }
}
</style>